import React from 'react';
import { ModalBackdrop } from './ModalBackdrop';
import { Download, Eye, Trash2, X } from 'lucide-react';
import '../styles/ProjectListModal.css'; // CSS 파일 import
export const ProjectListModal = ({ 
  projects, 
  isOpen, 
  onClose, 
  onLoad,
  onPreview,
  onDelete 
}) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClose={onClose}>
      <div className="max-w-4xl w-full mx-4 bg-white/95 backdrop-blur rounded-xl shadow-xl">
        {/* 헤더 영역 */}
        <div className="flex items-center justify-between p-6 border-b">
          <div>
            <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              프로젝트 목록
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              총 {projects.length}개의 프로젝트가 있습니다
            </p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="닫기"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* 프로젝트 목록 영역 */}
        <div className="p-6">
          <div className="h-[60vh] overflow-y-auto pr-2 space-y-4 custom-scrollbar">
            {projects.map(project => (
              <div
                key={project.id}
                onClick={() => onLoad(project.id)}
                className="group relative p-4 rounded-lg border border-gray-200 bg-white/50 hover:bg-white/90 hover:shadow-md transition-all duration-200 cursor-pointer"
              >
                <div className="flex items-start justify-between">
                  <div className="space-y-1">
                    <h3 className="font-semibold text-lg text-gray-900">
                      {project.title}
                    </h3>
                    <div className="flex items-center text-sm text-gray-500">
                      <span className="mr-2">생성일</span>
                      <span>{new Date(project.created_at).toLocaleString()}</span>
                    </div>
                  </div>

                  <div className="flex items-center gap-1" onClick={e => e.stopPropagation()}>
                    <button 
                      onClick={() => onLoad(project.id)}
                      className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                      title="불러오기"
                    >
                      <Download className="w-4 h-4" />
                    </button>
                    <button 
                      onClick={() => onPreview(project.id, project.title)}
                      className="p-2 text-green-600 hover:bg-green-50 rounded-lg transition-colors"
                      title="미리보기"
                    >
                      <Eye className="w-4 h-4" />
                    </button>
                    <button 
                      onClick={() => onDelete(project.id, project.title)}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                      title="삭제"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            
            {projects.length === 0 && (
              <div className="flex flex-col items-center justify-center py-12 text-gray-500">
                <p className="text-lg font-medium">저장된 프로젝트가 없습니다</p>
                <p className="text-sm mt-1">새로운 프로젝트를 시작해보세요</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ProjectListModal;