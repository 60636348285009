export const fetchApi = async (endpoint, options = {}) => {
    try {
      const response = await fetch(endpoint, {
        ...options,
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
        },
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "서버 오류가 발생했습니다.");
      }
  
      return await response.json();
    } catch (error) {
      throw new Error(error.message || "네트워크 오류가 발생했습니다.");
    }
  }