// 기본값 상수
export const DEFAULT_VALUES = {
  bookTitle: "",
  bookContent: ``,
  targetAudience: "",
  genre: "non-fiction-tech",
  totalPages: "5",
  tocLevel: "2",
  textStyle: "professional",
  baseWritingGuide: `1.기본원칙
      - 비유를 이용하여 이해하기 쉽게 작성
      - 경어체를 사용하되 너무 딱딱하지 않게 작성
2. 문장 구조
      - 비교 및 정리가 필요할 경우 표 형식 이용
      - 삽화 이미지가 필요한 시점에 https://placehold.co/500x200 이미지를 삽입
      - 그 아래에 일러스트레이션형태의 심플한 이미지 생성을 위한 미드저니용 프롬프트를 인용 형태로 작성
      - 그 외 흐름도, 다이어그램, 마인드맵, 타임라인, 차트가 필요할 경우 mermaid스크립트를 작성해줘, 그림을 그릴 때 위에서 아래쪽의 방향이 아니라 왼쪽에서 오른쪽 방향을 우선적으로 고려해줘
      - 중요 포인트는 볼드체 처리`,
  chapterCount: 5,
  titlesPerChapter: 1,
  aiModel: "gpt-4o-mini",
};
export const REQUIRED_FORMDATA = [
  { field: "bookTitle", label: "책 제목" },
  { field: "bookContent", label: "주요 내용" },
  { field: "genre", label: "장르" },
  { field: "targetAudience", label: "대상 독자" },
  { field: "totalPages", label: "총 페이지 수" },
  { field: "tocLevel", label: "목차 레벨" },
  { field: "chapterCount", label: "챕터 수" },
  { field: "titlesPerChapter", label: "챕터당 소제목 수" },
  { field: "secretKey", label: "비밀번호" },

];
// 장르 옵션
export const GENRE_OPTIONS = [
  { key: "", value: "", label: "장르 선택" },
  { key: "non-fiction-tech", value: "non-fiction-tech", label: "논픽션 - 기술/IT" },
  { key: "non-fiction-business", value: "non-fiction-business", label: "논픽션 - 비즈니스/경영" },
  { key: "non-fiction-self-help", value: "non-fiction-self-help", label: "논픽션 - 자기계발" },
  { key: "non-fiction-science", value: "non-fiction-science", label: "논픽션 - 과학/공학" },
  { key: "non-fiction-social", value: "non-fiction-social", label: "논픽션 - 사회/정치" },
  { key: "fiction-novel", value: "fiction-novel", label: "픽션 - 소설" },
  { key: "fiction-fantasy", value: "fiction-fantasy", label: "픽션 - 판타지" },
  { key: "fiction-sf", value: "fiction-sf", label: "픽션 - SF" },
  { key: "education-textbook", value: "education-textbook", label: "교육 - 교과서/참고서" },
  { key: "education-tutorial", value: "education-tutorial", label: "교육 - 튜토리얼/가이드" },
  { key: "children", value: "children", label: "아동/청소년" },
  { key: "essay", value: "essay", label: "에세이" },
  { key: "biography", value: "biography", label: "전기/회고록" },
];

// 텍스트 스타일 옵션
export const TEXT_STYLE_OPTIONS = [
  { key: "professional", value: "professional", label: "전문가 스타일" },
  { key: "mz", value: "mz", label: "MZ세대 말투" },
  { key: "casual", value: "casual", label: "편안한 대화체" },
  { key: "academic", value: "academic", label: "학술적 스타일" },
  { key: "storytelling", value: "storytelling", label: "스토리텔링 방식" },
  { key: "questionare", value: "questionare", label: "FAQ방식" },
  { key: "custom", value: "custom", label: "직접 입력" },
];

// AI 모델 옵션
export const AI_MODEL_OPTIONS = [
  { value: "gpt-4o-mini", label: "GPT-4o Mini" },
  { value: "gpt-4o", label: "GPT-4o" },
  { value: "gpt-4", label: "GPT-4" },
  { value: "gpt-4-turbo", label: "GPT-4 Turbo" },


];

// 토스트 메시지 타입
export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
};

// 토스트 메시지 색상
export const TOAST_COLORS = {
  [TOAST_TYPES.SUCCESS]: "bg-green-500",
  [TOAST_TYPES.ERROR]: "bg-red-500",
  [TOAST_TYPES.INFO]: "bg-blue-500",
  [TOAST_TYPES.WARNING]: "bg-yellow-500",
};

// 모달 타입
export const MODAL_TYPES = {
  CONTENT: "content",
  PREVIEW: "preview",
  PROJECT_LIST: "projectList",
  SAVE_AS: "saveAs",
};

// 텍스트 스타일 설명
export const TEXT_STYLE_DESCRIPTIONS = {
  professional: "전문적이고 격식 있는 어조를 사용하며, 정확한 전문 용어와 객관적인 서술을 중심으로 작성합니다. 문장은 명확하고 간결하게 구성됩니다.",
  mz: "MZ세대의 언어 습관을 반영하여, 최신 트렌드를 반영한 표현과 이모티콘을 적절히 사용합니다. 친근하고 캐주얼한 톤으로, 때로는 인터넷 용어나 신조어를 포함합니다.",
  casual: "일상적인 대화체를 사용하여 친근하고 편안한 느낌을 줍니다. 어려운 용어는 쉽게 풀어서 설명하고, 독자와 대화하는 듯한 어조를 유지합니다.",
  academic: "학술적이고 체계적인 서술을 중심으로, 논리적 구조와 객관적 근거를 강조합니다. 정확한 인용과 참조를 포함하며, 공식적인 학술 용어를 사용합니다.",
  storytelling: "이야기를 들려주듯 서술하며, 구체적인 예시와 사례를 통해 내용을 전달합니다. 독자의 흥미를 유지하기 위한 내러티브 구조를 활용합니다.",
  questionare: "질문과 답변 형식으로 구성하여 독자의 궁금증을 직접적으로 해소합니다. 실제 사용 사례와 문제 해결 방법을 중심으로 설명합니다.",
};

export const GENRE_DESCRIPTIONS = {
  "non-fiction-tech": "논픽션 - 기술/IT",
  "non-fiction-business": "논픽션 - 비즈니스/경영",
  "non-fiction-self-help": "논픽션 - 자기계발",
  "non-fiction-science": "논픽션 - 과학/공학",
  "non-fiction-social": "논픽션 - 사회/정치",
  "fiction-novel": "픽션 - 소설",
  "fiction-fantasy": "픽션 - 판타지",
  "fiction-sf": "픽션 - SF",
  "education-textbook": "교육 - 교과서/참고서",
  "education-tutorial": "교육 - 튜토리얼/가이드",
  children: "아동/청소년",
  essay: "에세이",
  biography: "전기/회고록",
};

// CSV 템플릿 내용
export const CSV_TEMPLATE_CONTENT = `Level,Title,Pages
  1,서론,10
  2,개요,5
  2,연구 배경,5
  1,이론적 배경,50
  2,주요 개념 정의,15
  3,핵심 용어 설명,7
  3,관련 이론 검토,8
  2,선행 연구 분석,20
  3,국내 연구 동향,10
  3,해외 연구 동향,10
  1,결론,10`;

// API 엔드포인트
export const API_ENDPOINTS = {
  GENERATE_TOC: "/api/generate-toc",
  GENERATE_CONTENT: "/api/generate-content",
  GENERATE_CHAPTER: "/api/generate-chapter-markdown",
  PROJECTS: "/api/projects",
  CONTENT: "/api/content",
  SAVE_CONTENT: "/api/save-content",
  UPLOAD_CSV: "/api/upload-toc-csv",
  DOWNLOAD_CSV: "/api/download-toc-csv",
  DOWNLOAD_TEMPLATE: "/api/download-toc-template",
};

// 유효성 검사 메시지
export const VALIDATION_MESSAGES = {
  REQUIRED_FIELD: "이 필드는 필수입니다.",
  INVALID_PAGES: "페이지 수는 1 이상이어야 합니다.",
  INVALID_TOC_LEVEL: "목차 레벨은 1에서 4 사이여야 합니다.",
  INVALID_CHAPTER_COUNT: "챕터 수는 1 이상이어야 합니다.",
  INVALID_TITLES_PER_CHAPTER: "챕터당 제목 수는 1 이상이어야 합니다.",
  CONFIRM_DELETE: "정말 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
  CONFIRM_NEW_PROJECT: "현재 작업 중인 내용이 모두 초기화됩니다. 계속하시겠습니까?",
  CONFIRM_TOC_REGENERATE: "현재 목차가 모두 삭제되고 새로 생성됩니다. 계속하시겠습니까?",
};
