import React, { useState, useCallback } from 'react';

export const useConfirm = () => {
  const [dialog, setDialog] = useState({
    isOpen: false,
    title: '',
    description: '',
    confirmText: '확인',
    cancelText: '취소',
    extraButton: null,
    resolve: null,
  });

  const openConfirm = useCallback((options = {}) => {
    return new Promise(resolve => {
      setDialog({
        isOpen: true,
        title: options.title || '확인',
        description: options.description || '계속 진행하시겠습니까?',
        confirmText: options.confirmText || '확인',
        cancelText: options.cancelText || '취소',
        extraButton: options.extraButton || null,
        resolve,
      });
    });
  }, []);

  const handleClose = useCallback((result) => {
    setDialog(prev => {
      if (prev.resolve) {
        prev.resolve(result);
      }
      return {
        ...prev,
        isOpen: false,
      };
    });
  }, []);

  const ConfirmDialog = useCallback(() => {
    if (!dialog.isOpen) return null;

    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={(e) => e.target === e.currentTarget && handleClose(false)}
      >
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 overflow-hidden" 
             onClick={e => e.stopPropagation()}>
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900">
              {dialog.title}
            </h2>
            <p className="mt-2 text-sm text-gray-500">
              {dialog.description}
            </p>
          </div>

          <div className="px-6 py-4 bg-gray-50 flex justify-end space-x-3">
            <button
              onClick={() => handleClose(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {dialog.cancelText}
            </button>
            {dialog.extraButton && (
              <button
                onClick={() => handleClose('extra')}
                className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  dialog.extraButton.className || 'bg-blue-500 hover:bg-blue-600'
                }`}
              >
                {dialog.extraButton.text}
              </button>
            )}
            <button
              onClick={() => handleClose(true)}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {dialog.confirmText}
            </button>
          </div>
        </div>
      </div>
    );
  }, [dialog, handleClose]);

  return [ConfirmDialog, openConfirm];
};