import { TEXT_STYLE_DESCRIPTIONS,GENRE_DESCRIPTIONS,REQUIRED_FORMDATA } from "./libs/constants";

// UUID 생성
const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// 파일 관련 유틸리티
const fileUtils = {
  // 파일 다운로드
  downloadFile: (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  },

  // 마크다운 파일 생성
  createMarkdownFile: (content, filename) => {
    const blob = new Blob([content], { type: "text/markdown;charset=UTF-8" });
    fileUtils.downloadFile(blob, `${filename}.md`);
  },

  // CSV 파일 생성
  createCsvFile: (content, filename) => {
    const BOM = "\uFEFF"; // UTF-8 BOM
    const blob = new Blob([BOM + content], { type: "text/csv;charset=UTF-8" });
    fileUtils.downloadFile(blob, `${filename}.csv`);
  },

  // 파일 이름 정리 (특수문자 제거)
  sanitizeFilename: (filename) => {
    return filename.replace(/[\\/:*?"<>|]/g, "_");
  },
};

// 날짜 관련 유틸리티
const dateUtils = {
  // 현재 날짜를 YYYY-MM-DD 형식으로 반환
  getCurrentDate: () => {
    return new Date().toISOString().slice(0, 10);
  },

  // 날짜를 로컬 문자열로 포맷팅
  formatDate: (date) => {
    return new Date(date).toLocaleString();
  },

  // 파일명에 사용할 타임스탬프 생성
  getTimestamp: () => {
    return new Date().toISOString().replace(/[:.]/g, "-");
  },
};

// 목차 관련 유틸리티
const tocUtils = {
  //csv
  genCsv: (currentToc, level = 1) => {
    const csvRows = ["Level,Title,Pages"];
    function addToCsv(items, level) {
      for (const item of items) {
        // 제목에 쉼표가 있는 경우를 대비해 큰따옴표로 감싸고,
        // 제목 내의 큰따옴표는 두 번 써서 이스케이프
        const escapedTitle = item.title.replace(/"/g, '""');
        csvRows.push(`${level},"${escapedTitle}",${item.pages}`);
        if (item.children && item.children.length > 0) {
          addToCsv(item.children, level + 1);
        }
      }
    }

    addToCsv(currentToc, level);
    return "\uFEFF" + csvRows.join("\n");
  },
  // 목차에서 특정 ID를 가진 항목 찾기
  findItemById: (toc, id) => {
    for (const item of toc) {
      if (String(item.id) === String(id)) return item;
      if (item.children) {
        const found = tocUtils.findItemById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  },

  // 목차 구조 생성 (CSV 데이터로부터)
  buildTocStructure: (items) => {
    const result = [];
    const stack = [{ level: 0, children: result }];

    for (const item of items) {
      item.id = item.id || generateUUID();

      while (stack[stack.length - 1].level >= item.level) {
        stack.pop();
      }
      const parent = stack[stack.length - 1];
      parent.children.push(item);
      stack.push(item);
    }

    return result;
  },

  // 목차 레벨 유효성 검사
  validateTocLevels: (items) => {
    const maxLevel = Math.max(...items.map((item) => item.level));

    for (let level = 2; level <= maxLevel; level++) {
      const hasCurrentLevel = items.some((item) => item.level === level);
      const hasParentLevel = items.some((item) => item.level === level - 1);

      if (hasCurrentLevel && !hasParentLevel) {
        return false;
      }
    }
    return true;
  },

  // 챕터 컨텍스트 찾기 (생성 시 사용)
  findChapterContext: (toc, targetId) => {
    const result = [];

    // 주어진 id의 부모와 그 자식들을 찾는 함수
    function traverse(items, parentItems = null) {
      for (const item of items) {
        // 현재 아이템의 자식들 중에서 targetId를 찾는다
        if (item.children) {
          const hasTarget = item.children.some(
            (child) => String(child.id) === String(targetId)
          );
          if (hasTarget) {
            // 찾았다면 현재 아이템(부모)과 모든 자식들을 결과에 추가
            result.push(item);
            item.children.forEach((child) => {
              result.push(child);
            });
            return true;
          }

          // 자식들 중에 없다면 더 깊이 들어가서 탐색
          if (traverse(item.children, item)) {
            return true;
          }
        }
      }
      return false;
    }

    traverse(toc);
    return result
      .map(
        (item) =>
          `${"\t".repeat(item.level - 1)}${item.level}. ${item.title} (${
            item.pages
          }페이지)`
      )
      .join("\n");
  },
};

// 텍스트 스타일 관련 유틸리티
const styleUtils = {
  // 텍스트 스타일 설명 가져오기
  getStyleDescription: (style) => {
    return TEXT_STYLE_DESCRIPTIONS[style] || style;
  },
  // 장르 가져오기
  getGenreDescription: (genre) => {
    return GENRE_DESCRIPTIONS[genre] || genre;
  },

  // 마크다운 텍스트 정리
  sanitizeMarkdown: (text) => {
    return text
      .replace(/\r\n/g, "\n")
      .replace(/\n{3,}/g, "\n\n")
      .trim();
  },
};

// 숫자 관련 유틸리티
const numberUtils = {
  // 범위 내의 숫자인지 확인
  isInRange: (number, min, max) => {
    const num = parseInt(number);
    return !isNaN(num) && num >= min && num <= max;
  },

  // 페이지 수 계산 (총 페이지 수를 챕터 수에 따라 분배)
  calculatePageDistribution: (totalPages, chapters) => {
    const basePages = Math.floor(totalPages / chapters);
    const remainder = totalPages % chapters;

    return Array(chapters)
      .fill(basePages)
      .map((pages, index) => (index < remainder ? pages + 1 : pages));
  },
};

// 검증 유틸리티
const validateUtils = {
  // 필수 필드 검증
  required: (value) => {
    return (
      value !== undefined && value !== null && value.toString().trim() !== ""
    );
  },

  // 숫자 범위 검증
  numberRange: (value, min, max) => {
    const num = parseInt(value);
    return !isNaN(num) && num >= min && num <= max;
  },

  // 이메일 형식 검증
  email: (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  },

  // URL 형식 검증
  url: (value) => {
    try {
      new URL(value);
      return true;
    } catch {
      return false;
    }
  },
};

// 디바운스 함수
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// 스로틀 함수
const throttle = (func, limit) => {
  let inThrottle;
  return function executedFunction(...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

// 로컬 스토리지 유틸리티
const storageUtils = {
  // 데이터 저장
  set: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      console.error("Local storage save error:", e);
      return false;
    }
  },

  // 데이터 가져오기
  get: (key, defaultValue = null) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.error("Local storage read error:", e);
      return defaultValue;
    }
  },

  // 데이터 삭제
  remove: (key) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      console.error("Local storage remove error:", e);
      return false;
    }
  },
};
//formData
const validator = {
  checkFormData : (formData) =>{
    // 필수 값 검증
    const missingFields = REQUIRED_FORMDATA.filter(({ field }) => {
      const value = formData[field];
      if (!value && value !== 0) return true;
      if (typeof value === "string" && value.trim() === "") return true;
      if (typeof value === "number" && value <= 0) return true;
      return false;
    });

    if (missingFields.length > 0) {
      const missingFieldLabels = missingFields.map(({ label }) => label);
      throw new Error(`다음 필수 항목을 입력해주세요: ${missingFieldLabels.join(", ")}`);
    }
  }
}
// 에러 처리 유틸리티
const errorUtils = {
  // 사용자 친화적인 에러 메시지 생성
  getUserFriendlyError: (error) => {
    if (error.response) {
      // API 에러
      switch (error.response.status) {
        case 401:
          return "인증이 필요합니다. 다시 로그인해 주세요.";
        case 403:
          return "이 작업을 수행할 권한이 없습니다.";
        case 404:
          return "요청하신 리소스를 찾을 수 없습니다.";
        case 500:
          return "서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.";
        default:
          return (
            error.response.data?.message || "알 수 없는 오류가 발생했습니다."
          );
      }
    } else if (error.request) {
      // 네트워크 에러
      return "서버에 연결할 수 없습니다. 인터넷 연결을 확인해 주세요.";
    } else {
      // 기타 에러
      return error.message || "알 수 없는 오류가 발생했습니다.";
    }
  },

  // 에러 로깅
  logError: (error, context = "") => {
    console.error(`Error in ${context}:`, {
      message: error.message,
      stack: error.stack,
      context,
    });
  },
};

export const utils = {
  generateUUID,
  fileUtils,
  dateUtils,
  tocUtils,
  styleUtils,
  numberUtils,
  validateUtils,
  debounce,
  throttle,
  storageUtils,
  errorUtils,
  validator
};

// default export를 위한 구문
export default utils;
