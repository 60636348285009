import React  from 'react';
import { ModalBackdrop } from './ModalBackdrop';

export const SaveAsModal = ({ 
    isOpen, 
    onClose, 
    onSave,
    defaultTitle = '' 
  }) => {
    const [newTitle, setNewTitle] = React.useState(defaultTitle);
  
    React.useEffect(() => {
      if (isOpen) {
        setNewTitle(defaultTitle);
      }
    }, [isOpen, defaultTitle]);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (newTitle.trim()) {
        onSave(newTitle.trim());
      }
    };
  
    if (!isOpen) return null;
  
    return (
      <ModalBackdrop onClose={onClose}>
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <h3 className="text-lg font-semibold mb-4">다른 이름으로 저장</h3>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="newProjectTitle"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                새 프로젝트 제목
              </label>
              <input
                type="text"
                id="newProjectTitle"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="새 프로젝트 제목을 입력하세요"
                autoFocus
              />
            </div>
            
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                취소
              </button>
              <button
                type="submit"
                disabled={!newTitle.trim()}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
              >
                저장
              </button>
            </div>
          </form>
        </div>
      </ModalBackdrop>
    );
  };