import {
  PlusCircle,
  Edit,
  Trash2,
  FileText,
  Eye,
  BookOpen,
  Upload,
  Download,
  FileUp,
  Loader2,
} from "lucide-react";

export const TocDisplay = ({
  toc,
  projectId,
  isGeneratingToc,
  isTocConfirmed,
  isEditMode,
  onToggleEditMode,
  onGenerateContent,
  onViewContent,
  onConfirmToc,
  onRegenerateToc,
  onPreview,
  onCsvUpload,
  onCsvDownload,
  onCsvTemplate,
  onEditChapter,
  onDeleteChapter,
  onAddSubChapter,
}) => {
  const renderTocItems = (items, level = 0) => {
    if (!Array.isArray(items)) return null;

    return (
      <ul className={level === 0 ? "space-y-1.5" : "mt-1.5 space-y-1 ml-3"}>
        {items.map((item) => (
          <li
            key={item.id}
            className={`flex items-start group ${isGeneratingToc ? 'opacity-50' : ''}`}
            data-id={item.id}
          >
            <div className="flex-grow">
              <div className="flex items-center group">
                {isEditMode && (
                  <div className="flex items-center mr-1.5 gap-0.5">
                    <button
                      onClick={() => onAddSubChapter(item.id, item.level)}
                      className="p-1 text-blue-500 hover:bg-blue-50 rounded-full transition-colors disabled:opacity-50"
                      title="하위 챕터 추가"
                      disabled={isGeneratingToc}
                    >
                      <PlusCircle className="w-3.5 h-3.5" />
                    </button>
                    <button
                      onClick={() => onEditChapter(item.id)}
                      className="p-1 text-yellow-500 hover:bg-yellow-50 rounded-full transition-colors disabled:opacity-50"
                      title="챕터 수정"
                      disabled={isGeneratingToc}
                    >
                      <Edit className="w-3.5 h-3.5" />
                    </button>
                    <button
                      onClick={() => onDeleteChapter(item.id)}
                      className="p-1 text-red-500 hover:bg-red-50 rounded-full transition-colors disabled:opacity-50"
                      title="챕터 삭제"
                      disabled={isGeneratingToc}
                    >
                      <Trash2 className="w-3.5 h-3.5" />
                    </button>
                  </div>
                )}

                <div
                  onClick={() => (!isGeneratingToc && item.generated ? onViewContent(item) : null)}
                  className={`flex items-center flex-grow ${
                    item.generated && !isGeneratingToc
                      ? "cursor-pointer hover:bg-gray-50 rounded px-2 py-0.5 -ml-2"
                      : ""
                  }`}
                >
                  <span
                    className={`font-medium ${
                      level === 0
                        ? "text-base"
                        : level === 1
                        ? "text-sm"
                        : "text-xs"
                    }`}
                  >
                    {item.title}
                  </span>
                  <span className="ml-1.5 text-xs text-gray-500">
                    ({item.pages || 0}페이지)
                  </span>
                  {item.generated && (
                    <Eye className="w-3.5 h-3.5 ml-1.5 text-green-500" />
                  )}
                </div>
              </div>

              {item.children && renderTocItems(item.children, level + 1)}
            </div>

            <div className="flex items-center gap-1">
              {(!item.children || item.children.length === 0) &&
                !item.generated && (
                  <button
                    onClick={() => onGenerateContent(item)}
                    className={`
                    inline-flex items-center gap-1 px-2 py-0.5 rounded-md text-xs transition-colors
                    ${
                      isTocConfirmed && !isGeneratingToc
                        ? "text-blue-500 hover:bg-blue-50"
                        : "text-gray-400 cursor-not-allowed"
                    }`}
                    disabled={!isTocConfirmed || isGeneratingToc}
                    title="컨텐츠 작성"
                  >
                    <FileText className="w-3 h-3" />
                    <span>작성</span>
                  </button>
                )}

              {/* 하위 노드가 있는 경우에만 '챕터' 버튼 표시 */}
              {item.children && item.children.length > 0 && (
                <button
                  onClick={() => onGenerateContent(item, true)}
                  className={`
                    inline-flex items-center gap-1 px-2 py-0.5 rounded-md text-xs text-purple-500 hover:bg-purple-50 transition-colors
                    ${!isTocConfirmed || isGeneratingToc ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                  disabled={!isTocConfirmed || isGeneratingToc}
                  title="전체 챕터 작성"
                >
                  <BookOpen className="w-3 h-3" />
                  <span>하위</span>
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="flex flex-col h-full relative">
       <div className={`flex-none p-3 border-b ${isGeneratingToc ? 'opacity-50' : ''}`}>
        <div className="flex items-center">
          <div className="flex items-center flex-grow gap-2">
            <h2 className="text-lg font-semibold">목차</h2>
            {isGeneratingToc && (
              <div className="flex items-center gap-1.5 text-blue-500">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span className="text-sm font-medium">생성 중...</span>
              </div>
            )}
          </div>
          <div className="flex items-center gap-1.5">
            <button
              onClick={onCsvUpload}
              className="p-1.5 text-gray-500 hover:bg-gray-100 rounded-md transition-colors disabled:opacity-50"
              title="CSV 업로드"
              disabled={isGeneratingToc}
            >
              <Upload className="w-4 h-4" />
            </button>
            <button
              onClick={onCsvDownload}
              className="p-1.5 text-gray-500 hover:bg-gray-100 rounded-md transition-colors disabled:opacity-50"
              title="CSV 다운로드"
              disabled={isGeneratingToc}
            >
              <Download className="w-4 h-4" />
            </button>
            <button
              onClick={onCsvTemplate}
              className="p-1.5 text-gray-500 hover:bg-gray-100 rounded-md transition-colors disabled:opacity-50"
              title="CSV 템플릿"
              disabled={isGeneratingToc}
            >
              <FileUp className="w-4 h-4" />
            </button>
            <div className="w-px h-4 bg-gray-200 mx-1" />
            <button
              onClick={onToggleEditMode}
              className={`p-1.5 ${isEditMode ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:bg-gray-100'} rounded-md transition-colors disabled:opacity-50`}
              title="수정 모드"
              disabled={isGeneratingToc}
            >
              <Edit className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div className={`flex-1 overflow-y-auto p-3 ${isGeneratingToc ? 'opacity-50' : ''}`}>
        {toc && toc.length > 0 ? (
          renderTocItems(toc)
        ) : (
          <p className="text-gray-500 text-center py-4 text-sm">
            아직 생성된 목차가 없습니다.
          </p>
        )}
      </div>

      <div className={`flex-none p-3 bg-gray-50 border-t space-y-2 ${isGeneratingToc ? 'opacity-50' : ''}`}>
      {toc && toc.length > 0 ?
        <div className="grid grid-cols-2 gap-2">
          <button
            onClick={onRegenerateToc}
            className="w-full px-3 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium transition-colors flex items-center justify-center gap-1.5 disabled:opacity-50"
            disabled={isGeneratingToc}
          >
            재생성
          </button>
          <button
            onClick={onConfirmToc}
            className="w-full px-3 py-2 rounded-md bg-green-500 hover:bg-green-600 text-white text-sm font-medium transition-colors flex items-center justify-center gap-1.5 disabled:opacity-50"
            disabled={isGeneratingToc}
          >
            저장
          </button>
        </div> : "" }
      </div>
    </div>
  );
};

export default TocDisplay;