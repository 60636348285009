import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles.css';
// import './global.css';


// React 18의 새로운 루트 API 사용
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);