import React from 'react';
import { FilePlus, FolderOpen, Save, Eye, FileOutput, Menu, X } from 'lucide-react';
import { Button } from "./ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

export const Header = ({ 
  onNewProject, 
  onLoadProject, 
  onSaveAsProject,
  onPreviewProject,
  onToggleSidebar,
  isSidebarOpen 
}) => {
  return (
    <div className="flex justify-between items-center py-4 px-6 border-b bg-white/90 backdrop-blur supports-[backdrop-filter]:bg-white/60">
      <div className="flex items-center gap-4">
        <Button
          variant="ghost"
          size="sm"
          className="p-2 relative w-9 h-9"
          onClick={onToggleSidebar}
          title={isSidebarOpen ? "사이드바 닫기" : "사이드바 열기"}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <Menu className={`w-5 h-5 absolute transition-all duration-200 ${
              isSidebarOpen 
                ? 'opacity-0 scale-0 rotate-90' 
                : 'opacity-100 scale-100 rotate-0'
            }`} />
            <X className={`w-5 h-5 absolute transition-all duration-200 ${
              isSidebarOpen 
                ? 'opacity-100 scale-100 rotate-0' 
                : 'opacity-0 scale-0 -rotate-90'
            }`} />
          </div>
        </Button>
        <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
          StoryForge v0.1
        </h1>
      </div>
      
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="sm"
          className="hidden sm:flex items-center gap-2"
          onClick={onNewProject}
        >
          <FilePlus className="w-4 h-4" />
          신규 프로젝트
        </Button>

        <Button
          variant="outline"
          size="sm"
          className="hidden sm:flex items-center gap-2"
          onClick={onLoadProject}
        >
          <FolderOpen className="w-4 h-4" />
          불러오기
        </Button>

        <Button
          variant="outline"
          size="sm"
          className="hidden sm:flex items-center gap-2"
          onClick={onSaveAsProject}
        >
          <Save className="w-4 h-4" />
          다른 이름으로 저장
        </Button>

        <Button
          variant="outline"
          size="sm"
          className="hidden sm:flex items-center gap-2"
          onClick={onPreviewProject}
        >
          <Eye className="w-4 h-4" />
          미리보기
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className="sm:hidden"
            >
              메뉴
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white border shadow-lg rounded-md" align="end">
            <DropdownMenuLabel>프로젝트 관리</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={onNewProject}>
              <FilePlus className="w-4 h-4 mr-2" />
              신규 프로젝트
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onLoadProject}>
              <FolderOpen className="w-4 h-4 mr-2" />
              프로젝트 불러오기
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onSaveAsProject}>
              <FileOutput className="w-4 h-4 mr-2" />
              다른 이름으로 저장
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onPreviewProject}>
              <Eye className="w-4 h-4 mr-2" />
              미리보기
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Header;