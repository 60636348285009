import React from "react";
import { GENRE_OPTIONS, REQUIRED_FORMDATA, TEXT_STYLE_OPTIONS, AI_MODEL_OPTIONS } from "../libs/constants";

export const BookInfoForm = ({ formData, onFormChange, onGenerateToc, onSaveProject, isGeneratingToc }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormChange({ ...formData, [name]: value });
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const numberValue = Math.max(0, parseInt(value) || 0);
    onFormChange({ ...formData, [name]: numberValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onGenerateToc();
  };

  const isFieldRequired = (fieldName) => {
    return REQUIRED_FORMDATA.some((item) => item.field === fieldName);
  };

  const renderRequired = (fieldName) => {
    return isFieldRequired(fieldName) ? <span className="text-red-500">*</span> : null;
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none p-3 border-b">
        <h2 className="text-lg font-semibold">책 정보 입력</h2>
      </div>
      <div className="flex-1 overflow-y-auto p-3">
        <form onSubmit={handleSubmit} className="space-y-3">
          {/* 책 제목 */}
          <div>
            <label className="block text-xs font-medium text-gray-700">책 제목 {renderRequired("bookTitle")}</label>
            <input
              type="text"
              name="bookTitle"
              value={formData.bookTitle}
              onChange={handleChange}
              required={isFieldRequired("bookTitle")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              placeholder="책 제목을 입력해주세요"
            />
          </div>

          {/* 주요 내용 */}
          <div>
            <label className="block text-xs font-medium text-gray-700">주요 내용 {renderRequired("bookContent")}</label>
            <textarea
              name="bookContent"
              value={formData.bookContent}
              onChange={handleChange}
              required={isFieldRequired("bookContent")}
              rows="6"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border resize-none"
              placeholder="책의 주요 내용을 입력해주세요"
            />
          </div>

          {/* 장르, 예상 독자, 텍스트 스타일 그리드 */}
          <div className="grid grid-cols-3 gap-2">
            <div>
              <label className="block text-xs font-medium text-gray-700">장르 {renderRequired("genre")}</label>
              <select
                name="genre"
                value={formData.genre}
                onChange={handleChange}
                required={isFieldRequired("genre")}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              >
                {GENRE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-700">예상 독자 {renderRequired("targetAudience")}</label>
              <input
                type="text"
                name="targetAudience"
                value={formData.targetAudience}
                onChange={handleChange}
                required={isFieldRequired("targetAudience")}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              />
            </div>

            <div>
              <label className="block text-xs font-medium text-gray-700">텍스트 스타일 {renderRequired("textStyle")}</label>
              <select
                name="textStyle"
                value={formData.textStyle}
                onChange={handleChange}
                required={isFieldRequired("textStyle")}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              >
                {TEXT_STYLE_OPTIONS.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* 커스텀 스타일 입력 */}
          {formData.textStyle === "custom" && (
            <div>
              <label className="block text-xs font-medium text-gray-700">커스텀 스타일 설명 {renderRequired("customStyleDescription")}</label>
              <textarea
                name="customStyleDescription"
                value={formData.customStyleDescription}
                onChange={handleChange}
                required={isFieldRequired("customStyleDescription")}
                rows="2"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
                placeholder="원하는 글쓰기 스타일을 자세히 설명해주세요"
              />
            </div>
          )}

          {/* 수치 입력 그리드 */}
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="block text-xs font-medium text-gray-700">전체 페이지 수 {renderRequired("totalPages")}</label>
              <input
                type="number"
                name="totalPages"
                value={formData.totalPages}
                onChange={handleNumberChange}
                required={isFieldRequired("totalPages")}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              />
            </div>
            <div>
              <label className="block text-xs font-medium text-gray-700">목차 레벨 (1-4) {renderRequired("tocLevel")}</label>
              <input
                type="number"
                name="tocLevel"
                value={formData.tocLevel}
                onChange={handleNumberChange}
                required={isFieldRequired("tocLevel")}
                min="1"
                max="4"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              />
            </div>
            <div>
              <label className="block text-xs font-medium text-gray-700">챕터 수 {renderRequired("chapterCount")}</label>
              <input
                type="number"
                name="chapterCount"
                value={formData.chapterCount}
                onChange={handleNumberChange}
                required={isFieldRequired("chapterCount")}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              />
            </div>
            <div>
              <label className="block text-xs font-medium text-gray-700">챕터당 제목 수 {renderRequired("titlesPerChapter")}</label>
              <input
                type="number"
                name="titlesPerChapter"
                value={formData.titlesPerChapter}
                onChange={handleNumberChange}
                required={isFieldRequired("titlesPerChapter")}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              />
            </div>
          </div>

          {/* 기본 작성 가이드 */}
          <div>
            <label className="block text-xs font-medium text-gray-700">
              기본 작성 가이드 {renderRequired("baseWritingGuide")}
              <span className="text-gray-500 text-xs">(본문 작성 시 참고)</span>
            </label>
            <textarea
              name="baseWritingGuide"
              value={formData.baseWritingGuide}
              onChange={handleChange}
              required={isFieldRequired("baseWritingGuide")}
              rows="10"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border resize-none"
              placeholder="모든 챕터 작성에 적용될 기본 가이드라인을 입력하세요"
            />
          </div>
          <div className="grid grid-cols-2 gap-2">

          {/* AI 모델 선택 */}
          <div>
            <label className="block text-xs font-medium text-gray-700">AI 모델 선택 {renderRequired("aiModel")}</label>
            <select
              name="aiModel"
              value={formData.aiModel}
              onChange={handleChange}
              required={isFieldRequired("aiModel")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
            >
              {AI_MODEL_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* 임시 : 비밀번호 */}
          <div>
            <label className="block text-xs font-medium text-gray-700">Secret Key {renderRequired("secretKey")}</label>
            <input
              type="text"
              name="secretKey"
              value={formData.secretKey}
              onChange={handleChange}
              required={isFieldRequired("secretKey")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-1.5 text-sm border"
              placeholder="비밀번호를 입력하세요"
            />
          </div>
          </div>
        </form>
      </div>

      {/* 제출 버튼 */}
      <div className="flex-none p-3 bg-gray-50 border-t space-x-2 flex">
        <button
          onClick={handleSubmit}
          disabled={isGeneratingToc}
          className={`flex-1 px-3 py-2 rounded-md text-white text-sm font-medium
            ${isGeneratingToc ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"}`}
        >
          {isGeneratingToc ? "목차 생성 중..." : "목차 생성"}
        </button>
        <button
          onClick={onSaveProject}
          disabled={isGeneratingToc}
          className="flex-1 px-3 py-2 rounded-md text-white text-sm font-medium bg-green-500 hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          저장
        </button>
      </div>
    </div>
  );
};

export default BookInfoForm;
