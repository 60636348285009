import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Book from './pages/Book';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/book/:projectId" element={<Book />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;