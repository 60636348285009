import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Toast, Header, BookInfoForm, TocDisplay, ProjectListModal, SaveAsModal, useConfirm, PromptModal, Content } from "../components";
import BookViewer from "../components/BookViewer";
import { tocApi } from "../api/tocApi";
import { projectApi } from "../api/projectApi";
import { contentApi } from "../api/contentApi";
import BusyIndicator from "../components/ui/BusyIndicator";
import ResponsiveCarousel from "../components/ResponsiveCarousel";
import utils from "../utils";
import { DEFAULT_VALUES, TOAST_TYPES, REQUIRED_FORMDATA } from "../libs/constants";
import { util } from "prismjs";

export const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // ========== 상태 관리 ==========
  const [currentProject, setCurrentProject] = useState(null);
  const [currentToc, setCurrentToc] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isTocConfirmed, setIsTocConfirmed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [promptConfig, setPromptConfig] = useState({
    message: "",
    defaultValue: "",
    onSubmit: () => {},
  });
  const [tocChanges, setTocChanges] = useState({
    added: [],
    updated: [],
    deleted: [],
  });

  const [bookTitle, setBookTitle] = useState("My Book");
  const [selectedTocItem, setSelectedTocItem] = useState(null);
  const [formData, setFormData] = useState(DEFAULT_VALUES);
  const [contentConfirmButton, setContentConfirmButton] = useState(false);
  const [contentMode, setContentMode] = useState("view");
  const [activeModal, setActiveModal] = useState(null);
  const [modalContent, setModalContent] = useState("");
  const [content, setContent] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [isGeneratingToc, setIsGeneratingToc] = useState(false);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);

  const [isBusy, setIsBusy] = useState(false);

  const [toast, setToast] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const [ConfirmDialog, confirm] = useConfirm();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  // ========== 초기 로드 ==========
  useEffect(() => {
    loadProjects();

    // URL에서 projectId 쿼리 파라미터 확인
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get("projectId");
    if (projectId && (!currentProject || currentProject.id !== projectId)) {
      loadProjectById(projectId);
    }
  }, [location.search]);
  // ========== 유틸리티 함수 ==========

  const showToast = (message, type = TOAST_TYPES.SUCCESS) => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast((prev) => ({ ...prev, show: false })), 3000);
  };

  const loadProjects = async () => {
    try {
      const projectList = await projectApi.getList();
      setProjects(projectList);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };
  // TocDisplay에 전달할 props에 추가
  const trackTocChanges = (type, item) => {
    setTocChanges((prev) => {
      const newChanges = { ...prev };

      switch (type) {
        case "update":
          // 이미 added에 있는 항목이면 added 배열에서 업데이트
          if (newChanges.added.find((i) => i.id === item.id)) {
            newChanges.added = newChanges.added.map((i) => (i.id === item.id ? item : i));
          } else if (!newChanges.updated.some((existingItem) => existingItem.id === item.id)) {
            newChanges.updated.push(item);
          }
          break;
        case "delete":
          // added에 있던 항목이면 added에서만 제거
          if (newChanges.added.find((i) => i.id === item.id)) {
            newChanges.added = newChanges.added.filter((i) => i.id !== item.id);
          } else if (!newChanges.deleted.includes(item.id)) {
            // 아직 deleted에 없는 경우에만 추가
            newChanges.deleted.push(item.id);
          }
          // updated에서도 제거
          newChanges.updated = newChanges.updated.filter((i) => i.id !== item.id);
          break;
        default:
        case "add":
          // 이미 추가된 항목인지 확인
          if (!newChanges.added.some((existingItem) => existingItem.id === item.id)) {
            newChanges.added.push(item);
          }
          break;
      }

      return newChanges;
    });
  };

  // ========== 프로젝트 관리 핸들러 ==========
  const handleNewProject = async (showConfirm = true) => {
    if (currentProject && showConfirm) {
      const confirmed = await confirm({
        title: "새 프로젝트",
        description: "현재 작업 중인 내용이 모두 초기화됩니다. 계속하시겠습니까?",
        confirmText: "계속하기",
        cancelText: "취소",
      });

      if (!confirmed) return;
    }

    setCurrentProject(null);
    setCurrentToc(null);
    setIsTocConfirmed(false);
    setFormData(DEFAULT_VALUES);
    navigate("/", { replace: true }); // 쿼리 파라미터 제거
    showToast("새 프로젝트가 시작되었습니다.");
  };

  const handleSaveProject = async () => {
    try {
      const projectData = {
        ...formData,
        toc: currentToc,
      };

      const result = currentProject ? await projectApi.update(currentProject.id, projectData) : await projectApi.save(projectData);

      setCurrentProject(result.project);
      navigate(`/?projectId=${result.project.id}`, { replace: true });
      showToast("프로젝트가 저장되었습니다.");
      await loadProjects();
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };
  // 프로젝트 ID로 프로젝트 로드하는 함수 분리
  const loadProjectById = async (projectId) => {
    try {
      setIsBusy(true);
      const project = await projectApi.get(projectId);
      setCurrentProject(project);
      const toc = await tocApi.getList(projectId);
      setCurrentToc(toc);
      setFormData({
        bookTitle: project.title || "",
        bookContent: project.book_content || "",
        genre: project.genre || "",
        targetAudience: project.target_audience || "",
        totalPages: project.total_pages || "",
        tocLevel: project.toc_level || "",
        textStyle: project.text_style || "",
        baseWritingGuide: project.base_writing_guide || "",
        chapterCount: project.chapter_count || "",
        titlesPerChapter: project.titles_per_chapter || "",
        aiModel: project.ai_model || "",
      });
      setIsTocConfirmed(true);
      setActiveModal(null);
      setContent("");
      setSelectedTocItem(null);
      showToast("프로젝트를 불러왔습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
      navigate("/", { replace: true });
    } finally {
      setIsBusy(false);
    }
  };
  const handleLoadProject = async (projectId) => {
    try {
      // URL 업데이트 (replace: true로 설정하여 브라우저 히스토리에 새로운 항목을 추가하지 않음)
      navigate(`/?projectId=${projectId}`, { replace: true });
      // 실제 프로젝트 로드는 useEffect에서 처리됨
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
      navigate("/", { replace: true });
    }
  };

  const handleSaveAs = async (newTitle) => {
    try {
      const projectData = {
        ...formData,
        bookTitle: newTitle,
        toc: currentToc,
      };

      const result = await projectApi.save(projectData);
      setCurrentProject(result.project);
      setFormData((prev) => ({ ...prev, bookTitle: newTitle }));
      navigate(`/?projectId=${result.project.id}`, { replace: true });
      showToast("새 프로젝트로 저장되었습니다.");
      await loadProjects();
      setActiveModal(null);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  const handleDeleteProject = async (projectId, projectTitle) => {
    const confirmed = await confirm({
      title: "프로젝트 삭제",
      description: `"${projectTitle}" 프로젝트를 삭제하시겠습니까?`,
      confirmText: "계속하기",
      cancelText: "취소",
    });

    if (!confirmed) return;

    try {
      await projectApi.delete(projectId);
      if (currentProject?.id === projectId) {
        navigate("/", { replace: true }); // 현재 프로젝트가 삭제된 경우 홈으로 이동
      }
      await loadProjects();
      showToast("프로젝트가 삭제되었습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // Preview 핸들러 수정
  // const handlePreview = async (projectId) => {
  //   if (!projectId) return;
  //   navigate(`/book/${projectId}`);
  // };
  // ========== 미리보기 관련 핸들러 ==========
  const handlePreview = async (projectId = currentProject?.id) => {
    if (!projectId) return;

    try {
      const { title, content } = await projectApi.preview(projectId);
      setModalContent(content);
      setBookTitle(title); // 받아
      setActiveModal("preview");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // ========== 목차 관리 핸들러 ==========
  // 목차 생성
  const handleGenerateToc = async () => {
    try {
      utils.validator.checkFormData(formData);
      // // 필수 값 검증
      // const missingFields = REQUIRED_FORMDATA.filter(({ field }) => {
      //   const value = formData[field];
      //   if (!value && value !== 0) return true;
      //   if (typeof value === "string" && value.trim() === "") return true;
      //   if (typeof value === "number" && value <= 0) return true;
      //   return false;
      // });

      // if (missingFields.length > 0) {
      //   const missingFieldLabels = missingFields.map(({ label }) => label);
      //   throw new Error(`다음 필수 항목을 입력해주세요: ${missingFieldLabels.join(", ")}`);
      // }

      const trackExistingItemsAsDeleted = (items) => {
        items.forEach((item) => {
          trackTocChanges("delete", item);
          if (item.children) {
            trackExistingItemsAsDeleted(item.children);
          }
        });
      };

      // Track new items as added
      // ID와 order_index를 한 번에 추가하는 함수
      const addIdsAndOrderToToc = (items, parentId = null) => {
        return items.map((item, index) => {
          const newItem = {
            ...item,
            id: crypto.randomUUID(),
            parent_id: parentId,
            order_index: index,
          };

          // 하위 항목이 있는 경우 재귀적으로 처리
          if (Array.isArray(newItem.children) && newItem.children.length > 0) {
            newItem.children = addIdsAndOrderToToc(newItem.children, newItem.id);
          }

          return newItem;
        });
      };

      // 변경사항 추적 함수
      const trackNewItemsAsAdded = (items) => {
        items.forEach((item) => {
          trackTocChanges("add", item);
          if (item.children) {
            trackNewItemsAsAdded(item.children);
          }
        });
      };
      // If there's an existing TOC, mark all items as deleted
      if (currentToc && currentToc.length > 0) {
        const confirmed = await confirm({
          title: "목차 재생성",
          description: "현재 목차를 삭제 후 다시 생성합니다. 계속하시겠습니까?",
          confirmText: "계속하기",
          cancelText: "취소",
        });

        if (!confirmed) return;
        trackExistingItemsAsDeleted(currentToc);
      }
      setIsGeneratingToc(true);
      setCurrentTabIndex(1);
      //formdata의 textStyle
      formData.textStyleDescription = utils.styleUtils.getStyleDescription(formData.textStyle);
      //formdata의 장르
      formData.genreDescription = utils.styleUtils.getGenreDescription(formData.genre);
      const result = await tocApi.generate(formData);
      // TOC 데이터에 ID 추가
      const processedToc = addIdsAndOrderToToc(result.toc);
      trackNewItemsAsAdded(processedToc);
      setCurrentToc(processedToc);
      showToast("목차가 생성되었습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    } finally {
      setIsGeneratingToc(false);
    }
  };

  // 목차 확정
  const handleConfirmToc = async () => {
    if (!currentToc) {
      showToast("저장할 목차가 없습니다.", TOAST_TYPES.ERROR);
      return;
    }
    if (!currentProject?.id) {
      showToast("프로젝트를 먼저 저장하세요.", TOAST_TYPES.ERROR);
      return;
    }

    try {
      // 변경사항이 있는 경우에만 API 호출
      if (tocChanges.added.length > 0 || tocChanges.updated.length > 0 || tocChanges.deleted.length > 0) {
        await tocApi.saveChanges(currentProject.id, tocChanges);
        // 변경사항 초기화
        setTocChanges({
          added: [],
          updated: [],
          deleted: [],
        });
      }

      setIsTocConfirmed(true);
      showToast("목차가 저장되었습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // ========== 컨텐츠 관리 핸들러 ==========
  const generateChildrenContent = async (parentId) => {
    try {
      let generateEmptyChild = false;
      // 현재 TOC에서 parentId에 해당하는 항목 찾기
      const findParentItem = (items, targetId) => {
        for (const item of items) {
          if (item.id === targetId) return item;
          if (item.children) {
            const found = findParentItem(item.children, targetId);
            if (found) return found;
          }
        }
        return null;
      };

      const parentItem = findParentItem(currentToc, parentId);
      if (!parentItem || !parentItem.children || parentItem.children.length === 0) {
        showToast("생성할 하위 목차가 없습니다.", TOAST_TYPES.ERROR);
        return;
      }

      //확인
      const confirmed = await confirm({
        title: "컨텐츠 생성",
        description: `하위 챕터 ${parentItem.children.length}건에 대해 생성할까요? 계속하시겠습니까?`,
        confirmText: "계속하기",
        cancelText: "취소",
        extraButton: {
          text: "빈 챕터만 생성",
          className: "bg-green-500 hover:bg-green-600",
        },
      });

      if (!confirmed) return;
      if (confirmed === "extra") {
        // 다른 이름으로 저장 버튼
        generateEmptyChild = true;
      }

      // 진행 상황을 보여주기 위한 상태 업데이트
      setIsGeneratingContent(true);
      // 각 하위 항목에 대해 순차적으로 컨텐츠 생성
      for (const child of parentItem.children) {
        if (generateEmptyChild && child.generated) {
          continue;
        }
        setSelectedTocItem(child);
        setContentTitle(child.title);
        setContent("하위 목차 컨텐츠 생성 중...");
        const data = {
          currentToc,
          tocItem: child,
          formData,
          projectId: currentProject?.id,
          isChapter: false,
        };

        let generatedContent = "";
        const onEnd = (fullText) => {
          generatedContent = fullText; // 생성된 전체 텍스트를 저장
        };
        await contentApi.generate(data, null, onEnd);
        await contentApi.save(currentProject.id, child.id, generatedContent);
        // TOC 데이터 새로고침
        await refreshTocData(currentProject.id, child.id);
      }
      showToast("하위 목차 컨텐츠가 모두 생성되었습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    } finally {
      setIsGeneratingContent(false);
      setContent("");
    }
  };
  //컨텐츠 변경
  const handleContentChange = (newContent) => {
    setContent(newContent);
  };
  // 컨텐츠 생성
  const handleGenerateContent = async (tocItem, isChapter = false) => {
    if (!tocItem) {
      showToast("선택된 목차 항목이 없습니다.", TOAST_TYPES.ERROR);
      return;
    }
  
    //content가 이미 있을 경우
    if (tocItem.generated) {
      const confirmed = await confirm({
        title: "컨텐츠 생성",
        description: "현재 내용을 삭제 후 다시 생성합니다. 계속하시겠습니까?",
        confirmText: "계속하기",
        cancelText: "취소",
      });
  
      if (!confirmed) return;
    }
  
    try {
      utils.validator.checkFormData(formData);
      setIsGeneratingContent(true);
      setContentTitle(tocItem.title);
  
      // 새로운 tocItem으로 항상 업데이트
      setSelectedTocItem(tocItem);
  
      // 컨텐츠 초기화
      setContent("");
      setContentConfirmButton(true);
  
      const onProgress = (newContent) => {
        setContent((prev) => prev + newContent);
      };
  
      formData.textStyleDescription = utils.styleUtils.getStyleDescription(formData.textStyle);
      formData.genreDescription = utils.styleUtils.getGenreDescription(formData.genre);
      
      const data = {
        currentToc,
        tocItem,
        formData,
        projectId: currentProject?.id,
        isChapter,
      };
      
      setCurrentTabIndex(2);
      
      if (isChapter) {
        await generateChildrenContent(tocItem.id);
      } else {
        await contentApi.generate(data, onProgress);
      }
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
      // 에러 발생 시 선택된 항목과 컨텐츠 초기화
      setSelectedTocItem(null);
      setContent("");
    } finally {
      setIsGeneratingContent(false);
    }
  };

  // 컨텐츠 조회
  const handleViewContent = async (tocItem) => {
    if (!currentProject?.id || !tocItem) {
      showToast("프로젝트 또는 목차 항목을 찾을 수 없습니다.", TOAST_TYPES.ERROR);
      return;
    }

    try {
      setCurrentTabIndex(2);
      const result = await contentApi.get(currentProject.id, tocItem.id);
      setContentTitle(tocItem.title);
      setContent(result.content);
      setSelectedTocItem(tocItem); // 선택된 tocItem 저장
      // setActiveModal("content");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // 컨텐츠 저장
  const refreshTocData = async (projectId, tocItemId) => {
    try {
      const updatedTocItem = await tocApi.get(tocItemId);
      const updateTocTree = (items) => {
        return items.map((item) => {
          if (item.id === tocItemId) {
            return {
              ...item,
              ...updatedTocItem,
              children: item.children, // Preserve existing children
            };
          }
          if (item.children && item.children.length > 0) {
            return {
              ...item,
              children: updateTocTree(item.children),
            };
          }
          return item;
        });
      };

      // Update only the specific TOC item in the current TOC
      setCurrentToc((prevToc) => updateTocTree(prevToc));
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  const handleSaveContent = async (newContent) => {
    if (!selectedTocItem) {
      showToast("저장할 목차 항목을 찾을 수 없습니다.", TOAST_TYPES.ERROR);
      return;
    }
    if (!newContent) {
      showToast("변경된 내용이 없습니다.", TOAST_TYPES.ERROR);
      return;
    }

    try {
      await contentApi.save(currentProject.id, selectedTocItem.id, newContent);
      setActiveModal(null);
      showToast("컨텐츠가 저장되었습니다.");
      setContent(newContent);
      setContentConfirmButton(false);
      //TODO : TOC만 리프레시 해주도록 하자, 아래 다 읽지 말고
      await refreshTocData(currentProject.id, selectedTocItem.id);
      // 저장 완료 후 선택된 항목 초기화
    setSelectedTocItem(null);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // ========== CSV 관련 핸들러 ==========
  // CSV 업로드
  const handleCsvUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const result = await tocApi.uploadCsv(file);

      // CSV 업로드로 추가되는 모든 항목을 추적
      const trackNewItems = (items, parentId = null) => {
        items.forEach((item) => {
          const itemWithParent = {
            ...item,
            parent_id: parentId,
            order_index: item.order_index || 0,
          };
          trackTocChanges("add", itemWithParent);
          if (item.children) {
            trackNewItems(item.children, item.id);
          }
        });
      };

      trackNewItems(result.toc);
      setCurrentToc(result.toc);
      showToast("CSV 파일을 성공적으로 가져왔습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    } finally {
      event.target.value = "";
    }
  };

  // CSV 다운로드
  const handleCsvDownload = async () => {
    try {
      // UTF-8 BOM 추가
      const csvContent = utils.tocUtils.genCsv(currentToc, 1);
      utils.fileUtils.createCsvFile(csvContent, `toc_${currentProject?.title || "untitled"}_${utils.dateUtils.getCurrentDate()}.csv`);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // CSV 템플릿 다운로드
  const handleCsvTemplate = async () => {
    try {
      const csvContent = utils.tocUtils.genCsv([], 1);
      utils.fileUtils.createCsvFile(csvContent, `toc_template_${utils.dateUtils.getCurrentDate()}.csv`);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // 프로젝트 다운로드
  const handleDownload = async () => {
    try {
      const blob = await projectApi.download(currentProject.id);
      utils.fileUtils.downloadFile(blob, `${utils.fileUtils.sanitizeFilename(currentProject.title)}.md`);
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  //목차 변경
  const updateTocItem = (toc, itemId, updateFn) => {
    return toc.map((item) => {
      if (item.id === itemId) {
        return updateFn(item);
      }
      if (item.children) {
        return {
          ...item,
          children: updateTocItem(item.children, itemId, updateFn),
        };
      }
      return item;
    });
  };

  // 챕터 수정 핸들러
  const handleEditChapter = async (chapterId) => {
    if (!currentToc) {
      showToast("목차가 확정된 후에는 수정할 수 없습니다.", TOAST_TYPES.WARNING);
      return;
    }

    const findChapter = (items, id, parentId = null, index = 0) => {
      for (const [idx, item] of items.entries()) {
        if (item.id === id) {
          return {
            ...item,
            parent_id: parentId,
            order_index: item.order_index || idx, // 기존 order_index 유지하거나 없으면 현재 인덱스 사용
          };
        }
        if (item.children) {
          const found = findChapter(item.children, id, item.id, idx);
          if (found) return found;
        }
      }
      return null;
    };

    const chapter = findChapter(currentToc, chapterId);
    if (!chapter) {
      showToast("챕터를 찾을 수 없습니다.", TOAST_TYPES.ERROR);
      return;
    }

    setPromptConfig({
      message: "새 챕터 제목을 입력하세요:",
      defaultValue: chapter.title,
      onSubmit: async (newTitle) => {
        if (!newTitle || newTitle === chapter.title) return;

        try {
          const updatedToc = updateTocItem(currentToc, chapterId, (item) => {
            const updatedItem = {
              ...item,
              title: newTitle,
              parent_id: chapter.parent_id,
              order_index: chapter.order_index,
            };
            trackTocChanges("update", updatedItem);
            return updatedItem;
          });

          setCurrentToc(updatedToc);
          showToast("챕터가 수정되었습니다.");
        } catch (error) {
          showToast(error.message, TOAST_TYPES.ERROR);
        }
      },
    });
    setActiveModal("prompt");
  };

  // 챕터 삭제 핸들러 수정
  const handleDeleteChapter = async (chapterId) => {
    if (!currentToc) {
      showToast("목차가 확정된 후에는 삭제할 수 없습니다.", TOAST_TYPES.WARNING);
      return;
    }

    const confirmDelete = await confirm({
      title: "챕터 삭제",
      description: "이 챕터와 모든 하위 챕터가 삭제됩니다. 계속하시겠습니까?",
      confirmText: "계속하기",
      cancelText: "취소",
    });

    if (!confirmDelete) return;

    try {
      const trackDeletedItems = (items) => {
        items.forEach((item) => {
          trackTocChanges("delete", item);
          if (item.children) {
            trackDeletedItems(item.children);
          }
        });
      };

      // 삭제할 챕터와 그 하위 챕터들 찾기
      const findChapterWithChildren = (items, id) => {
        for (const item of items) {
          if (item.id === id) return item;
          if (item.children) {
            const found = findChapterWithChildren(item.children, id);
            if (found) return found;
          }
        }
        return null;
      };

      const chapterToDelete = findChapterWithChildren(currentToc, chapterId);
      if (chapterToDelete) {
        trackDeletedItems([chapterToDelete]);
      }

      const removeChapter = (items) => {
        return items.filter((item) => {
          if (item.id === chapterId) return false;
          if (item.children) {
            item.children = removeChapter(item.children);
          }
          return true;
        });
      };

      const updatedToc = removeChapter(currentToc);
      setCurrentToc(updatedToc);
      showToast("챕터가 삭제되었습니다.");
    } catch (error) {
      showToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  // 하위 챕터 추가 핸들러 수정
  const handleAddSubChapter = async (parentId, level) => {
    if (!currentToc) {
      showToast("목차가 확정된 후에는 추가할 수 없습니다.", TOAST_TYPES.WARNING);
      return;
    }

    if (level >= 3) {
      showToast("더 이상 하위 챕터를 추가할 수 없습니다.", TOAST_TYPES.WARNING);
      return;
    }

    setPromptConfig({
      message: "새 챕터 제목을 입력하세요:",
      defaultValue: "",
      onSubmit: async (newTitle) => {
        if (!newTitle) return;

        try {
          const newChapter = {
            id: crypto.randomUUID(),
            level: level + 1,
            title: newTitle,
            generated: false,
            pages: 5,
            parent_id: parentId, // parent_id 추가
            children: [],
            order_index: 0, // 기본 order_index 설정
          };

          trackTocChanges("add", newChapter);

          const updatedToc = updateTocItem(currentToc, parentId, (item) => ({
            ...item,
            children: [...(item.children || []), newChapter],
          }));

          setCurrentToc(updatedToc);
          showToast("새 챕터가 추가되었습니다.");
        } catch (error) {
          showToast(error.message, TOAST_TYPES.ERROR);
        }
      },
    });
    setActiveModal("prompt");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // ========== 렌더링 ==========
  return (
    <div className="h-screen flex flex-col">
      <div className="flex-none">
        <Header
          onNewProject={handleNewProject}
          onLoadProject={() => setActiveModal("projectList")}
          onSaveAsProject={() => setActiveModal("saveAs")}
          onPreviewProject={() => handlePreview(currentProject?.id, currentProject?.title)}
          onToggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
        />
      </div>

      {/* Main Content Area with Responsive Carousel */}
      <div className="flex-1 p-2 min-h-0">
        <ResponsiveCarousel
          activeIndex={currentTabIndex}
          onIndexChange={setCurrentTabIndex}
          isSidebarOpen={isSidebarOpen}
          bookInfoComponent={<BookInfoForm formData={formData} onFormChange={setFormData} onGenerateToc={handleGenerateToc} onSaveProject={handleSaveProject} isGeneratingToc={isGeneratingToc} />}
          tocComponent={
            <TocDisplay
              toc={currentToc}
              projectId={currentProject?.id}
              isGeneratingToc={isGeneratingToc}
              isTocConfirmed={isTocConfirmed}
              isEditMode={isEditMode}
              onToggleEditMode={() => setIsEditMode(!isEditMode)}
              onGenerateContent={handleGenerateContent}
              onViewContent={handleViewContent}
              onConfirmToc={handleConfirmToc}
              onRegenerateToc={handleGenerateToc}
              onPreview={() => handlePreview(currentProject?.id, currentProject?.title)}
              onCsvUpload={handleCsvUpload}
              onCsvDownload={handleCsvDownload}
              onCsvTemplate={handleCsvTemplate}
              onEditChapter={handleEditChapter}
              onDeleteChapter={handleDeleteChapter}
              onAddSubChapter={handleAddSubChapter}
            />
          }
          contentComponent={
            content && selectedTocItem ? (
              <Content
                isOpen={activeModal === "content"}
                onClose={() => setActiveModal(null)}
                content={content}
                mode={contentMode}
                contentTitle={contentTitle}
                onModeChange={setContentMode}
                onRegenerate={() => handleGenerateContent(selectedTocItem)}
                onConfirm={handleSaveContent}
                onChange={handleContentChange}
                isGeneratingContent={isGeneratingContent}
                showConfirmButton={contentConfirmButton}
              />
            ) : (
              <div className="h-full flex items-center justify-center">
                <div className="text-center text-gray-500">목차에서 선택해서 내용을 확인하세요</div>
              </div>
            )
          }
        />
      </div>

      {/* Modals and Toast remain unchanged */}
      <BookViewer content={modalContent} isOpen={activeModal === "preview"} onClose={() => setActiveModal(null)} onDownload={handleDownload} useModal={true} bookTitle={bookTitle} />
      <ProjectListModal
        isOpen={activeModal === "projectList"}
        onClose={() => setActiveModal(null)}
        projects={projects}
        onLoad={handleLoadProject}
        onPreview={handlePreview}
        onDelete={handleDeleteProject}
      />
      <SaveAsModal isOpen={activeModal === "saveAs"} onClose={() => setActiveModal(null)} onSave={handleSaveAs} defaultTitle={formData.bookTitle} />
      <ConfirmDialog />
      <PromptModal isOpen={activeModal === "prompt"} onClose={() => setActiveModal(null)} onSubmit={promptConfig.onSubmit} message={promptConfig.message} defaultValue={promptConfig.defaultValue} />
      <BusyIndicator isLoading={isBusy} />
      {/* Toast */}
      {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />}
    </div>
  );
};

export default Main;
