import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ResponsiveCarousel = ({ 
  isSidebarOpen,
  bookInfoComponent,
  tocComponent,
  contentComponent,
  activeIndex: controlledIndex,
  onIndexChange
}) => {
  // 내부 상태는 controlled/uncontrolled 모두 지원하도록 구현
  const [internalIndex, setInternalIndex] = useState(0);
  
  // controlledIndex가 제공되면 그 값을 사용, 아니면 내부 상태 사용
  const activeIndex = controlledIndex !== undefined ? controlledIndex : internalIndex;

  const handleIndexChange = (newIndex) => {
    // 외부에서 제어하는 경우 onIndexChange 호출
    if (onIndexChange) {
      onIndexChange(newIndex);
    } else {
      // 내부에서만 제어하는 경우 내부 상태 업데이트
      setInternalIndex(newIndex);
    }
  };
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const components = [
    { title: "책 정보", content: bookInfoComponent },
    { title: "목차", content: tocComponent },
    { title: "내용", content: contentComponent }
  ];

  if (!isMobile) {
    return (
      <div className="flex h-full gap-2">
         <div className={`${isSidebarOpen ? 'w-[20%]' : 'w-0 opacity-0'} transition-all duration-300 overflow-hidden border rounded-lg bg-white`}>
          {bookInfoComponent}
        </div>
        <div className={`${isSidebarOpen ? 'w-[30%]' : 'w-[30%]'} transition-all duration-300 overflow-hidden border rounded-lg bg-white`}>
          {tocComponent}
        </div>
        <div className={`${isSidebarOpen ? 'w-[50%]' : 'w-[70%]'} transition-all duration-300 overflow-hidden border rounded-lg bg-white`}>
          {contentComponent}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center px-4 py-3 bg-white border-b">
        <button
          onClick={() => handleIndexChange(Math.max(0, activeIndex - 1))}
          className={`w-8 h-8 flex items-center justify-center rounded-full transition-all
            ${activeIndex === 0 
              ? 'text-gray-300 cursor-not-allowed' 
              : 'text-gray-600 hover:bg-gray-100 active:bg-gray-200'}`}
          disabled={activeIndex === 0}
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        
        <div className="flex gap-1">
          {components.map((comp, idx) => (
            <button
              key={idx}
              onClick={() => handleIndexChange(idx)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200
                ${activeIndex === idx 
                  ? 'bg-blue-500 text-white shadow-sm' 
                  : 'bg-gray-50 text-gray-600 hover:bg-gray-100 active:bg-gray-200'}`}
            >
              {comp.title}
            </button>
          ))}
        </div>

        <button
          onClick={() => handleIndexChange(Math.min(components.length - 1, activeIndex + 1))}
          className={`w-8 h-8 flex items-center justify-center rounded-full transition-all
            ${activeIndex === components.length - 1 
              ? 'text-gray-300 cursor-not-allowed' 
              : 'text-gray-600 hover:bg-gray-100 active:bg-gray-200'}`}
          disabled={activeIndex === components.length - 1}
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      <div className="flex-1 overflow-hidden border rounded-lg bg-white">
        {components[activeIndex].content}
      </div>
    </div>
  );
};

export default ResponsiveCarousel;