import React from 'react';
import Markdown from "marked-react";
import {
  createCodeHighlightRenderer,
  createMermaidRenderer,
} from "./MarkedRenderer";

export const MarkdownViewer = ({ 
  content,
  className = "",
  style = {},
}) => {
  const mermaidRenderer = createMermaidRenderer();
  const codeHighlightRenderer = createCodeHighlightRenderer();
  
  const renderer = {
    code(code, language, index) {
      // Mermaid 렌더러 먼저 시도
      const mermaidResult = mermaidRenderer.code(code, language, index);
      if (mermaidResult) return mermaidResult;

      // 일반 코드 하이라이팅으로 폴백
      return codeHighlightRenderer.code(code, language, index);
    },
  };

  return (
    <div className={`prose prose-sm max-w-none ${className}`} style={style}>
      <Markdown value={content || ""} renderer={renderer} />
    </div>
  );
};