import React, { useState, useEffect, useRef, useCallback } from "react";
import { ModalBackdrop } from "./ModalBackdrop";
import { marked } from "marked";
import {
  ChevronLeft,
  ChevronRight,
  Minus,
  Plus,
  Search,
  Home,
  Eye,
  EyeOff,
} from "lucide-react";
import hljs from "highlight.js";
import "highlight.js/styles/paraiso-light.css";
import mermaid from "mermaid";
// mermaid 초기 설정
mermaid.initialize({
  startOnLoad: true,
  theme: "neutral",
  look: "handDrawn",
  securityLevel: "loose",
  // 파서 에러 처리를 위한 추가 설정
  logLevel: "error",
  secure: ["secure", "securityLevel", "startOnLoad", "theme"],
  error: function (error) {
    console.error("Mermaid error:", error);
  },
});
const defaultBookConfig = {
  pageWidth: 595,
  pageHeight: 842,
  margins: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
  },
};
const predefinedThemes = [
  { name: "기본", bgColor: "bg-neutral-50", textColor: "text-gray-900" },
  { name: "세피아", bgColor: "bg-amber-50", textColor: "text-gray-900" },
  { name: "다크", bgColor: "bg-gray-900", textColor: "text-gray-100" },
  { name: "그린", bgColor: "bg-emerald-50", textColor: "text-gray-900" },
];

export const BookViewer = ({
  content = "",
  isOpen = false,
  onClose,
  onDownload,
  bookTitle,
  bookConfig = defaultBookConfig,
  useModal = false,
  className = "",
}) => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const [fontSize, setFontSize] = useState(14);
  const [selectedTheme, setSelectedTheme] = useState(predefinedThemes[0]);
  const [jumpToPage, setJumpToPage] = useState("");
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const measureContainerRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      // 화면 크기나 UA 중 하나라도 모바일 조건을 만족하면 모바일로 처리
      const isMobileByWidth = window.innerWidth < 768;
      const isMobileDevice =
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      setIsMobile(isMobileByWidth || isMobileDevice);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const preprocessContent = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Find or create book-cover div
    let bookCover = tempDiv.querySelector(".book-cover");
    if (!bookCover) {
      bookCover = document.createElement("div");
      bookCover.className = "book-cover";
      tempDiv.insertBefore(bookCover, tempDiv.firstChild);
    }

    // Find title (h1) or create one from bookTitle prop
    let title = tempDiv.querySelector("h1");
    if (!title && bookTitle) {
      title = document.createElement("h1");
      title.textContent = bookTitle;
    }

    // Ensure title is inside book-cover
    if (title && title.parentElement !== bookCover) {
      // Remove title from its current position
      if (title.parentElement) {
        title.parentElement.removeChild(title);
      }
      // Insert at the beginning of book-cover
      bookCover.insertBefore(title, bookCover.firstChild);
    }

    return tempDiv.innerHTML;
  };

  const calculatePages = useCallback(
    (contentHtml) => {
      if (!measureContainerRef.current) return [];

      const container = measureContainerRef.current;
      const contentWidth =
        bookConfig.pageWidth -
        (bookConfig.margins.left + bookConfig.margins.right);
      const maxPageHeight =
        bookConfig.pageHeight -
        (bookConfig.margins.top + bookConfig.margins.bottom);

      container.style.width = `${contentWidth}px`;
      container.style.fontSize = `${fontSize}px`;

      const pageContents = [];
      let currentContent = "";

      const processedHtml = preprocessContent(contentHtml);
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = processedHtml;
      const elements = Array.from(tempDiv.children);

      // Ensure book cover with title is on the first page
      const coverIndex = elements.findIndex((el) =>
        el.classList.contains("book-cover")
      );
      if (coverIndex !== -1) {
        const coverElement = elements.splice(coverIndex, 1)[0];
        pageContents.push(coverElement.outerHTML);
      }

      elements.forEach((element) => {
        if (element.classList.contains("page-break")) {
          if (currentContent) {
            pageContents.push(currentContent);
            currentContent = "";
          }
          return;
        }

        // 현재 요소의 높이 측정
        container.innerHTML = currentContent + element.outerHTML;

        if (container.scrollHeight > maxPageHeight) {
          if (currentContent) {
            pageContents.push(currentContent);
            currentContent = element.outerHTML;
          } else {
            // 단일 요소가 페이지보다 큰 경우 강제 분할
            const clonedElement = element.cloneNode(true);
            const originalText = clonedElement.innerText;
            const words = originalText.split(" ");
            let currentText = "";
            let remainingWords = [...words];

            while (remainingWords.length > 0) {
              const testText = currentText + " " + remainingWords[0];
              clonedElement.innerText = testText;
              container.innerHTML = clonedElement.outerHTML;

              if (container.scrollHeight <= maxPageHeight) {
                currentText = testText;
                remainingWords.shift();
              } else {
                if (currentText) {
                  clonedElement.innerText = currentText.trim();
                  pageContents.push(clonedElement.outerHTML);
                  currentText = "";
                }
                container.innerHTML = "";
              }
            }

            if (currentText) {
              clonedElement.innerText = currentText.trim();
              currentContent = clonedElement.outerHTML;
            }
          }
        } else {
          currentContent += element.outerHTML;
        }
      });

      return pageContents;
    },
    [bookConfig, fontSize, bookTitle]
  );

  // 마크다운 변환 설정
  useEffect(() => {
    let mermaidId = 0;
    const renderer = new marked.Renderer();

    renderer.code = function ({ text: code, lang: language }) {
      if (language === "mermaid") {
        mermaidId++;
        const diagramId = `mermaid-diagram-${mermaidId}`;

        try {
          // mermaid 다이어그램 렌더링을 위한 컨테이너 생성
          return `<div class="mermaid" id="${diagramId}">${code}</div>`;
        } catch (e) {
          console.error("Mermaid rendering error:", e);
          return `<pre><code class="language-mermaid">${code}</code></pre>`;
        }
      }

      // 다른 언어의 코드 블록 처리
      if (!language) {
        return `<pre><code>${code}</code></pre>`;
      }

      try {
        const highlighted = hljs.highlight(code, {
          language: language,
        }).value;
        return `<pre><code class="hljs language-${language}">${highlighted}</code></pre>`;
      } catch (e) {
        console.error("Highlight error:", e);
        return `<pre><code>${code}</code></pre>`;
      }
    };

    marked.use({ renderer });
  }, []);
  useEffect(() => {
    if (isOpen && pages.length > 0) {
      try {
        mermaid.init(undefined, document.querySelectorAll(".mermaid"));
      } catch (e) {
        console.error("Mermaid initialization error:", e);
      }
    }
  }, [isOpen, pages, currentPage]);
  useEffect(() => {
    if (isOpen) {
      setCurrentPage(0);
      setIsProcessing(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !content) {
      setPages([]);
      return;
    }

    const processContent = async () => {
      try {
        const parsedHtml = marked(content);
        const newPages = calculatePages(parsedHtml);
        setPages(newPages);
      } catch (error) {
        console.error("Error processing content:", error);
        setPages([]);
      } finally {
        setIsProcessing(false);
      }
    };

    const timeoutId = setTimeout(processContent, 100);
    return () => clearTimeout(timeoutId);
  }, [content, isOpen, calculatePages]);

  if (!isOpen) return null;

  const navigate = (direction) => {
    if (isMobile) {
      setCurrentPage((prev) => {
        const next = prev + direction;
        return next >= 0 && next < pages.length ? next : prev;
      });
    } else {
      setCurrentPage((prev) => {
        const next = prev + direction * 2;
        return next >= 0 && next < pages.length ? next : prev;
      });
    }
  };

  const goToHome = () => {
    setCurrentPage(0);
  };

  const handleJumpToPage = (e) => {
    e.preventDefault();
    const pageNum = parseInt(jumpToPage);
    if (pageNum && pageNum > 0 && pageNum <= pages.length) {
      setCurrentPage(Math.floor((pageNum - 1) / 2) * 2);
    }
    setJumpToPage("");
  };

  const adjustFontSize = (delta) => {
    setFontSize((prev) => Math.min(Math.max(prev + delta, 12), 24));
  };

  const Toolbar = () => (
    <div className="relative">
      <div
        className={`transition-all duration-300 ease-in-out ${
          isToolbarVisible
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-full h-0 overflow-hidden"
        }`}
      >
        <div className="flex items-center justify-between px-6 py-3 border-b border-gray-200 bg-white">
          <div className="flex items-center space-x-4">
            <button
              onClick={goToHome}
              className="p-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-1"
            >
              <Home className="w-4 h-4" />
              <span className="text-sm">처음으로</span>
            </button>
            <form
              onSubmit={handleJumpToPage}
              className="flex items-center space-x-2"
            >
              <input
                type="number"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                placeholder="페이지 입력"
                className="w-20 px-2 py-1 border border-gray-300 rounded-md"
                min="1"
                max={pages.length}
              />
              <button
                type="submit"
                className="p-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                <Search className="w-4 h-4" />
              </button>
            </form>
          </div>

          <div className="flex items-center space-x-4">
            <select
              value={selectedTheme.name}
              onChange={(e) =>
                setSelectedTheme(
                  predefinedThemes.find(
                    (theme) => theme.name === e.target.value
                  )
                )
              }
              className="px-2 py-1 border border-gray-300 rounded-md"
            >
              {predefinedThemes.map((theme) => (
                <option key={theme.name} value={theme.name}>
                  {theme.name} 테마
                </option>
              ))}
            </select>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => adjustFontSize(-1)}
                className="p-1.5 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                <Minus className="w-4 h-4" />
              </button>
              <span className="text-sm">{fontSize}px</span>
              <button
                onClick={() => adjustFontSize(1)}
                className="p-1.5 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => setIsToolbarVisible(!isToolbarVisible)}
        className="absolute right-4 top-full transform translate-y-2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 transition-colors z-10"
        title={isToolbarVisible ? "도구 모음 숨기기" : "도구 모음 표시"}
      >
        {isToolbarVisible ? (
          <EyeOff className="w-4 h-4" />
        ) : (
          <Eye className="w-4 h-4" />
        )}
      </button>
    </div>
  );

  const BookCover = () => (
    <>
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: "#1b4d3e",
          boxShadow: "inset 0 0 60px rgba(0,0,0,0.3)",
        }}
      />

      <div
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.7' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.3'/%3E%3C/svg%3E")`,
          backgroundSize: "150px 150px",
        }}
      />

      <div
        className="absolute inset-8"
        style={{
          border: "2px solid rgba(255, 215, 0, 0.6)",
          padding: "20px",
        }}
      />

      {/* Corner Decorations */}
      {["tl", "tr", "bl", "br"].map((position) => (
        <svg
          key={position}
          className={`absolute w-16 h-16 ${
            position === "tl"
              ? "left-8 top-8"
              : position === "tr"
              ? "right-8 top-8"
              : position === "bl"
              ? "left-8 bottom-8"
              : "right-8 bottom-8"
          }`}
          viewBox="0 0 100 100"
          style={{
            transform: `translate(${position.includes("r") ? "50%" : "-50%"}, ${
              position.includes("b") ? "50%" : "-50%"
            }) 
                       scale(${position.includes("r") ? -1 : 1}, ${
              position.includes("b") ? -1 : 1
            })`,
          }}
        >
          <path
            d="M100 0 L60 0 Q40 0 40 20 L40 40 Q40 60 20 60 L0 60"
            fill="none"
            stroke="rgba(255, 215, 0, 0.6)"
            strokeWidth="2"
          />
        </svg>
      ))}

      {/* Decorative Lines */}
      {["top", "bottom"].map((position) => (
        <div
          key={position}
          className={`absolute ${
            position === "top" ? "top-24" : "bottom-24"
          } left-1/2 transform -translate-x-1/2`}
          style={{
            width: "80%",
            height: "2px",
            background:
              "linear-gradient(90deg, transparent, rgba(255, 215, 0, 0.6), transparent)",
          }}
        />
      ))}
    </>
  );
  const PageContent = ({ content, pageNumber }) => {
    const isBookCover = content?.includes('class="book-cover"');
    const containerRef = useRef(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
      const calculateScale = () => {
        if (!containerRef.current) return;
        const container = containerRef.current;
        const parentRect = container.parentElement.getBoundingClientRect();
        const availableHeight = parentRect.height - 64;

        // Calculate available width based on mobile or desktop view
        const availableWidth = isMobile
          ? parentRect.width - 32 // Subtract padding for mobile
          : (parentRect.width - 64) / 2; // For desktop, divide space for two pages

        const scaleX = availableWidth / bookConfig.pageWidth;
        const scaleY = availableHeight / bookConfig.pageHeight;
        const newScale = Math.min(scaleX, scaleY, 1);
        setScale(newScale);
      };

      calculateScale();
      window.addEventListener("resize", calculateScale);
      return () => window.removeEventListener("resize", calculateScale);
    }, [isMobile]);

    return (
      <div
        ref={containerRef}
        className={`flex items-start justify-center transition-transform duration-300 ${
          isMobile ? "px-0" : "px-4"
        } pt-4`}
      >
        <div
          style={{
            width: `${bookConfig.pageWidth}px`,
            height: `${bookConfig.pageHeight}px`,
            transform: `scale(${scale})`,
            transformOrigin: "top center",
            position: "relative",
          }}
          className={`${isBookCover ? "" : selectedTheme.bgColor} ${
            selectedTheme.textColor
          } shadow-lg`}
        >
          {isBookCover && <BookCover />}
          <div
            className="prose max-w-none h-full relative"
            style={{
              padding: isBookCover
                ? 0
                : `${bookConfig.margins.top}px ${bookConfig.margins.right}px ${bookConfig.margins.bottom}px ${bookConfig.margins.left}px`,
              fontSize: `${fontSize}px`,
              ...(isBookCover && {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "rgba(255, 215, 0, 0.9)",
                textAlign: "center",
                padding: "48px",
              }),
            }}
            dangerouslySetInnerHTML={{ __html: content || "" }}
          />
          {!isBookCover && pageNumber && (
            <div
              className={`absolute bottom-6 ${
                pageNumber % 2 === 0 ? "right-6" : "left-6"
              }`}
            >
              {pageNumber}
            </div>
          )}
        </div>
      </div>
    );
  };

  const MainContent = () => (
    <div className={`flex flex-col h-screen bg-white ${className}`}>
      <div
        ref={measureContainerRef}
        className="prose max-w-none absolute opacity-0 pointer-events-none"
        style={{
          width: `${
            bookConfig.pageWidth -
            (bookConfig.margins.left + bookConfig.margins.right)
          }px`,
          visibility: "hidden",
          fontSize: `${fontSize}px`,
        }}
      />

      <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200 bg-white">
        <h2 className="text-xl font-semibold">
          {bookTitle || "전체 내용 미리보기"}
        </h2>
        <div className="flex items-center space-x-3">
          {onDownload && (
            <button
              onClick={onDownload}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              다운로드
            </button>
          )}
          {useModal && onClose && (
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="flex-none">
        <Toolbar />
      </div>
      <div className="flex-1 relative flex justify-center items-center">
        <button
          onClick={() => navigate(-1)}
          className="absolute left-2 z-10 bg-white rounded-full shadow-lg p-2"
          disabled={currentPage === 0}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <div className="flex h-full w-full justify-center items-center">
          {isMobile ? (
            <PageContent
              content={pages[currentPage]}
              pageNumber={currentPage + 1}
            />
          ) : (
            <>
              <PageContent
                content={pages[currentPage]}
                pageNumber={currentPage + 1}
              />
              <PageContent
                content={pages[currentPage + 1]}
                pageNumber={currentPage + 2}
              />
            </>
          )}
        </div>

        <button
          onClick={() => navigate(1)}
          className="absolute right-2 z-10 bg-white rounded-full shadow-lg p-2"
          disabled={
            isMobile
              ? currentPage >= pages.length - 1
              : currentPage >= pages.length - 2
          }
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );

  if (!isOpen) return null;

  if (useModal && isOpen) {
    return (
      <ModalBackdrop onClose={onClose}>
        <div className={`flex flex-col h-screen bg-white ${className}`}>
          <MainContent />
        </div>
      </ModalBackdrop>
    );
  }

  return <MainContent />;
};

export default BookViewer;
