import React, { useEffect, useRef, useState } from "react";
import { Pencil, Eye, Loader2 } from "lucide-react";
import MDEditor from "./ui/MDEditor";
import { MarkdownViewer } from "./ui/MarkdownViewer";

export const Content = ({
  contentTitle,
  content,
  isOpen,
  onClose,
  onRegenerate,
  onConfirm,
  onChange,
  isGeneratingContent,
  mode,
  onModeChange,
  showConfirmButton,
}) => {
  const contentRef = useRef(null);
  const editorRef = useRef(null);
  const [isChanged, setIsChanged] = useState(false);
  const [originalContent, setOriginalContent] = useState(content);
  const [editorHeight, setEditorHeight] = useState("500px");
  const prevContentLength = useRef(content?.length || 0);
  
  // Functions
  const scrollToBottom = () => {
    if (contentRef.current) {
      const scrollElement = contentRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      const scrollElement = contentRef.current;
      scrollElement.scrollTop = 0;
    }
  };

  // 컨테이너 높이에 따른 에디터 높이 계산
  const updateEditorHeight = () => {
    if (contentRef.current) {
      const containerHeight = contentRef.current.clientHeight;
      const newHeight = Math.max(containerHeight - 70, 300);
      setEditorHeight(`${newHeight}px`);
    }
  };

  const toggleMode = () => {
    onModeChange(mode === "edit" ? "view" : "edit");
  };

  // useEffect
  useEffect(() => {
    setOriginalContent(content);
    setIsChanged(false);

    // Only auto-scroll in view mode when content is being generated
    if (isGeneratingContent && mode === "view" && content?.length > prevContentLength.current) {
      setTimeout(scrollToBottom, 100);
    }

    prevContentLength.current = content?.length || 0;

    const editor = editorRef.current;
    if (editor && mode === "edit") {
      const editorInstance = editor.getInstance();
      if (editorInstance) {
        editorInstance.setMarkdown(content || "");
      }
    }

    updateEditorHeight();
    const resizeObserver = new ResizeObserver(updateEditorHeight);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [content, mode, isGeneratingContent]);

  useEffect(() => {
    if (!isOpen) {
      setIsChanged(false);
    }
  }, [isOpen]);

  // Events
  const handleConfirm = () => {
    let newContent = "";
    if (mode === "view") {
      newContent = content;
    } else {
      const editor = editorRef.current;
      if (editor) {
        const editorInstance = editor.getInstance();
        newContent = editorInstance.getMarkdown();
      }
    }
    onConfirm(newContent);
  };

  const handleChange = () => {
    const editor = editorRef.current;
    if (editor) {
      const editorInstance = editor.getInstance();
      const currentContent = editorInstance.getMarkdown();
      setIsChanged(currentContent !== originalContent);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex-none p-3 border-b">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h2 className="text-lg font-semibold">{contentTitle}</h2>
            {isGeneratingContent && (
              <div className="flex items-center gap-1.5 text-blue-500">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span className="text-sm font-medium">생성 중...</span>
              </div>
            )}
          </div>
          <button
            onClick={toggleMode}
            className={`p-1.5 rounded-md transition-colors ${mode === "edit" ? "bg-blue-50 text-blue-500" : "text-gray-500 hover:bg-gray-100"}`}
            title={mode === "edit" ? "읽기 모드" : "편집 모드"}
          >
            {mode === "edit" ? <Eye className="w-4 h-4" /> : <Pencil className="w-4 h-4" />}
          </button>
        </div>
      </div>

      <div ref={contentRef} className="flex-1 overflow-y-auto min-h-0 p-3">
        {mode === "edit" ? (
          <MDEditor 
            content={content} 
            height={editorHeight} 
            onChange={handleChange} 
            editorRef={editorRef} 
          />
        ) : (
          <MarkdownViewer content={content} />
        )}
      </div>

      <div className="flex-none p-3 bg-gray-50 border-t">
        <div className="flex justify-end gap-2">
          {mode === "edit" && (
            <>
              <button
                onClick={onRegenerate}
                disabled={isGeneratingContent}
                className="px-3 py-2 rounded-md bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 text-white text-sm font-medium transition-colors flex items-center justify-center gap-1.5"
              >
                재생성
              </button>
              <button
                onClick={handleConfirm}
                disabled={
                  isGeneratingContent || (mode === "edit" && !isChanged)
                }
                className="px-3 py-2 rounded-md bg-green-500 hover:bg-green-600 disabled:bg-gray-400 text-white text-sm font-medium transition-colors flex items-center justify-center gap-1.5 min-w-[120px]"
              >
                저장
              </button>
            </>
          )}
          {showConfirmButton && (
            <button
              onClick={handleConfirm}
              disabled={isGeneratingContent}
              className="px-3 py-2 rounded-md bg-green-500 hover:bg-green-600 disabled:bg-gray-400 text-white text-sm font-medium transition-colors flex items-center justify-center gap-1.5 min-w-[120px]"
            >
              확정
            </button>
          )}
        </div>
      </div>
    </div>
  );
};