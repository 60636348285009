import React, { useEffect, useState } from "react";
import { Copy, Check } from 'lucide-react';
import mermaid from "mermaid";
import hljs from "highlight.js";
import "highlight.js/styles/paraiso-light.css"; // 원하는 스타일을 import
// Mermaid 초기화 설정 업데이트
mermaid.initialize({
  startOnLoad: true,
  theme: "neutral",
  look: "handDrawn",
  securityLevel: "loose",
  // 파서 에러 처리를 위한 추가 설정
  logLevel: "error",
  secure: ["secure", "securityLevel", "startOnLoad", "theme"],
  error: function (error) {
    console.error("Mermaid error:", error);
  },
});

// Mermaid 다이어그램 렌더링 컴포넌트
const MermaidDiagram = ({ code, index }) => {
  const diagramId = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
  const [svgContent, setSvgContent] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const renderDiagram = async () => {
      try {
        const { svg } = await mermaid.render(diagramId, code);
        setSvgContent(svg);
        setError(null);
      } catch (error) {
        console.error("Mermaid rendering error:", error);
        setError(error);
        setSvgContent("");
      }
    };

    renderDiagram();
  }, [code]);

  if (error) {
    return (
      <div key={`error-${index}`} className="border rounded-md p-4 bg-black text-white">
        <div className="text-yellow-400 text-sm mb-2">다이어그램 구문 오류</div>
        <pre className="text-xs bg-gray-900 p-2 rounded overflow-x-auto">
          <code>{code}</code>
        </pre>
        <div className="mt-2 text-xs text-red-400">{error.message || "구문을 확인해주세요."}</div>
      </div>
    );
  }

  return <div key={`diagram-${index}`} className="mermaid-diagram" dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

// 메인 렌더러 설정
const createMermaidRenderer = () => ({
  code(code, language, index = Math.random().toString(36).substr(2, 9)) {
    if (!language?.match(/^mermaid/)) return false;

    try {
      return <MermaidDiagram key={`mermaid-${index}`} code={code} index={index} />;
    } catch (error) {
      return (
        <div key={`error-${index}`} className="border rounded-md p-4 bg-red-50">
          <div className="text-red-700 text-sm mb-2">다이어그램 구문 오류</div>
          <pre className="text-xs bg-white p-2 rounded overflow-x-auto">
            <code>{code}</code>
          </pre>
          <div className="mt-2 text-xs text-red-600">{error.message || "구문을 확인해주세요."}</div>
        </div>
      );
    }
  },
});
const CodeBlock = ({ code, language, highlighted }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy code:', err);
    }
  };

  return (
    <div className="relative group">
      <pre className="relative">
        <code
          className={`hljs ${language ? `language-${language}` : ""}`}
          dangerouslySetInnerHTML={{ __html: highlighted }}
        />
        <button
          onClick={handleCopy}
          className="absolute right-2 top-2 p-2 rounded-md bg-gray-700/30 hover:bg-gray-700/75 
                   opacity-60 group-hover:opacity-100 transition-all duration-100 z-50"
          aria-label="코드 복사"
        >
          {copied ? (
            <Check className="h-4 w-4 text-green-400" />
          ) : (
            <Copy className="h-4 w-4 text-gray-100" />
          )}
        </button>
      </pre>
    </div>
  );
};

const createCodeHighlightRenderer = () => ({
  code(code, language, index = Math.random().toString(36).substr(2, 9)) {
    let highlighted;

    try {
      if (language && hljs.getLanguage(language)) {
        highlighted = hljs.highlight(code, { language }).value;
      } else {
        highlighted = hljs.highlightAuto(code).value;
        if (language) {
          console.warn(
            `Language '${language}' is not supported by highlight.js, falling back to auto-detection`
          );
        }
      }
    } catch (e) {
      highlighted = hljs.escape(code);
      console.warn(`Failed to highlight code: ${e.message}`);
    }

    return (
      <CodeBlock
        key={`code-${index}`}
        code={code}
        language={language}
        highlighted={highlighted}
      />
    );
  },
});
//marked용
const createMermaidRendererMarked = () => ({
  code({ lang, text, index }) {
    if (lang && lang.match(/^mermaid/)) {
      try {
        return <MermaidDiagram key={`mermaid-${index}`} code={text} index={index} />;
      } catch (error) {
        return (
          <div className="border rounded-md p-4 bg-red-50">
            <div className="text-red-700 text-sm mb-2">다이어그램 구문 오류</div>
            <pre className="text-xs bg-white p-2 rounded overflow-x-auto">
              <code>{text}</code>
            </pre>
            <div className="mt-2 text-xs text-red-600">{error.message || "구문을 확인해주세요."}</div>
          </div>
        );
      }
    }

    return (
      <pre>
        <code>{text}</code>
      </pre>
    );
  },
});

export { MermaidDiagram, createMermaidRenderer, createCodeHighlightRenderer, createMermaidRendererMarked };
