import { fetchApi } from "./fetchApi";
export const projectApi = {
  // 프로젝트 목록 조회
  getList: async () => {
    return await fetchApi("/api/projects");
  },

  // 단일 프로젝트 조회
  get: async (projectId) => {
    return await fetchApi(`/api/projects/${projectId}`);
  },

  // 프로젝트 저장
  save: async (projectData) => {
    return await fetchApi("/api/projects", {
      method: "POST",
      body: JSON.stringify(projectData),
    });
  },

  // 프로젝트 수정
  update: async (projectId, projectData) => {
    return await fetchApi(`/api/projects/${projectId}`, {
      method: "PUT",
      body: JSON.stringify(projectData),
    });
  },

  // 프로젝트 삭제
  delete: async (projectId) => {
    return await fetchApi(`/api/projects/${projectId}`, {
      method: "DELETE",
    });
  },

  // 프로젝트 미리보기
  preview: async (projectId) => {
    const response = await fetch(`/api/projects/${projectId}/preview`);
    if (!response.ok) throw new Error("미리보기 생성에 실패했습니다.");
    return response.json();
  },

  // 프로젝트 다운로드
  download: async (projectId) => {
    const response = await fetch(`/api/projects/${projectId}/download`);
    if (!response.ok) throw new Error("다운로드에 실패했습니다.");
    return response.blob();
  },
};