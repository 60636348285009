import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BookViewer from "../components/BookViewer";
import { LazyBookViewer } from "../components/LazyBookViewer";
import { projectApi } from "../api/projectApi";

const Book = () => {
  const { projectId } = useParams();
  const [markdownContent, setMarkdownContent] = useState(null);
  const [bookTitle, setBookTitle] = useState("My Book"); // 기본값 설정

  useEffect(() => {
    handlePreview(projectId);
  }, []);

  const handlePreview = async (projectId) => {
    if (!projectId) return;

    try {
      const { title, content } = await projectApi.preview(projectId);
      setMarkdownContent(content);
      setBookTitle(title); 
    } catch (error) {
      setMarkdownContent('# 존재하지 않는 책번호입니다. 다시 확인해주세요😅');
    }
  };
  return (
    <div className="min-h-screen bg-gray-50">
      <BookViewer content={markdownContent} isOpen="true" bookTitle={bookTitle} className="max-w-[1400px] mx-auto" />
      {/* <LazyBookViewer 
        content={markdownContent} 
        isOpen={true} 
        bookTitle={bookTitle} 
        className="max-w-[1400px] mx-auto" 
      /> */}
    </div>
  );
};

export default Book;
