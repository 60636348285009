import { fetchApi } from "./fetchApi";
import { utils } from "../utils";
export const contentApi = {
  //toastui editor addImageBlobHook
  uploadImage:async(formData)=>{
    const response = await fetch("/api/content/uploadImage", {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Image upload failed');
    }
    return await response.json();
  },
  // 컨텐츠 저장
  save: async (projectId, tocItemId, content) => {
    return await fetchApi("/api/content", {
      method: "POST",
      body: JSON.stringify({
        projectId,
        tocItemId,
        content,
      }),
    });
  },
  // 컨텐츠 조회
  get: async (projectId, tocItemId) => {
    return await fetchApi(`/api/content/${projectId}/${tocItemId}`);
  },
  // 컨텐츠 생성 (스트리밍)
  generate: async (data, onProgress,onEnd) => {
    let fullText="";
    const tocItem = data.tocItem;
    const contextStr = utils.tocUtils.findChapterContext(data.currentToc, tocItem.id);
    const submitData = {
      contextStr,
      tocItem,
      ...data.formData,
      model: data.formData.aiModel,
    };
    const response = await fetch("/api/content/generate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(submitData),
      reactNative: { textStreaming: true },
    });

    if (!response.ok) throw new Error("컨텐츠 생성에 실패했습니다.");

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.content) {
                if(onProgress){
                  onProgress(data.content) 
                }
                // onProgress ? onProgress(data.content) : null
                fullText += data.content;
              }
            } catch (e) {
              console.error("Chunk parsing error:", e);
            }
          }
        }
      }
    } finally {
      reader.releaseLock();
      if(onEnd){
        onEnd(fullText);
      }
    }
  },
  enhance: async (data, onProgress) => {
    const response = await fetch("/api/content/enhance", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      reactNative: { textStreaming: true },
    });

    if (!response.ok) throw new Error("컨텐츠 생성에 실패했습니다.Secret Key 등을 확인하세요");

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.content) {
                console.log(data.content);
                onProgress(data.content);
              }
            } catch (e) {
              console.error("Chunk parsing error:", e);
            }
          }
        }
      }
    } finally {
      reader.releaseLock();
    }
  },
  
};
