import { fetchApi } from "./fetchApi";
export const tocApi = {
  //프로젝트 하위 리스트
  getList: async (projectId) => {
    return await fetchApi(`/api/toc/project/${projectId}`);
  },
  getRawList: async (projectId) => {
    return await fetchApi(`/api/toc/project/raw/${projectId}`);
  },

  get: async (id) => {
    return await fetchApi(`/api/toc/${id}`);
  },
  update: async (id, tocItem) => {
    return await fetchApi(`/api/toc/${id}`, {
      method: "PUT",
      body: JSON.stringify(tocItem),
    });
  },
  // 목차 생성
  generate: async (formData) => {
    return await fetchApi("/api/toc/generate", {
      method: "POST",
      body: JSON.stringify(formData),
    });
  },
  save:async(projectId, currentToc)=>{
    return await fetchApi(`/api/toc`, {
      method: "POST",
      body: JSON.stringify({projectId:projectId,toc:currentToc}),
    });
  },
  saveChanges: async (projectId, changes) => {
    try {
      const response = await fetch(`/api/toc/project/${projectId}/changes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(changes),
      });

      if (!response.ok) {
        throw new Error('목차 변경사항 저장에 실패했습니다.');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error('목차 변경사항 저장 중 오류가 발생했습니다: ' + error.message);
    }
  },
  // async saveBatch(projectId, changes) {
  //   /* 
  //   changes 배열의 각 항목 구조:
  //   {
  //     type: 'ADD' | 'UPDATE' | 'DELETE',
  //     timestamp: number,
  //     data: {
  //       id?: number,        // UPDATE/DELETE 시 필수
  //       parentId?: number,  // ADD 시 필수
  //       level: number,      // ADD/UPDATE 시 필수
  //       title?: string,     // ADD/UPDATE 시 필수
  //       children?: array    // 선택적
  //     }
  //   }
  //   */
  //   const response = await fetch(`/api/${projectId}/batch`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ changes }),
  //   });
  //   return response.json();
  // },

  // CSV 업로드
  // uploadCsv: async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   return await fetchApi("/api/upload-toc-csv", {
  //     method: "POST",
  //     headers: {}, // FormData를 사용할 때는 Content-Type 헤더를 제거
  //     body: formData,
  //   });
  // },

  // // CSV 다운로드
  // downloadCsv: async (toc) => {
  //   return await fetchApi("/api/download-toc-csv", {
  //     method: "POST",
  //     body: JSON.stringify({ toc }),
  //   });
  // },

  // // CSV 템플릿 다운로드
  // downloadTemplate: async () => {
  //   const response = await fetch("/api/download-toc-template");
  //   if (!response.ok) throw new Error("템플릿 다운로드에 실패했습니다.");
  //   return response.blob();
  // },
};
