import React from 'react';
import { ModalBackdrop } from './ModalBackdrop';

export const PromptModal = ({ 
  isOpen, 
  onClose, 
  onSubmit,
  message,
  defaultValue = '' 
}) => {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    if (isOpen) {
      setValue(defaultValue);
    }
  }, [isOpen, defaultValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.trim()) {
      onSubmit(value.trim());
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <ModalBackdrop onClose={onClose}>
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="promptInput"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              {message}
            </label>
            <input
              type="text"
              id="promptInput"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              autoFocus
            />
          </div>
          
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              취소
            </button>
            <button
              type="submit"
              disabled={!value.trim()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
            >
              확인
            </button>
          </div>
        </form>
      </div>
    </ModalBackdrop>
  );
};