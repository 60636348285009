import { Loader2 } from 'lucide-react';

const BusyIndicator = ({ isLoading }) => {
  if (!isLoading) return null;
  
  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex items-center gap-3">
        <Loader2 className="animate-spin text-blue-500" size={24} />
        <span className="text-gray-700 font-medium">처리중입니다...</span>
      </div>
    </div>
  );
};

export default BusyIndicator;