import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import uml from "@toast-ui/editor-plugin-uml";
import Modal from "./Modal";
import { contentApi } from "../../api/contentApi";

const MDEditor = ({ content, height, onChange, editorRef }) => {
  const popoverRef = useRef(null);
  const selectedTextRef = useRef("");
  const cursorOffsetRef = useRef({ start: 0, end: 0 }); // 커서 위치 저장을 위한 ref

  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: "",
    action: "",
    streamedContent: "",
  });
  const addImageBlobHook = async (blob, callback) => {
    try {
      // FormData 객체 생성
      const formData = new FormData();
      formData.append('image', blob,blob.name);
      const data = await contentApi.uploadImage(formData);
      // 업로드된 이미지 URL을 에디터에 삽입
      callback(data.imageUrl, 'alt text');
      return false; // 기본 이미지 업로드 동작 방지
    } catch (error) {
      console.error('Image upload error:', error);
      alert('이미지 업로드에 실패했습니다.');
    }
  };
  const handleModalClose = () => {
    setModalConfig({
      ...modalConfig,
      isOpen: false,
      streamedContent: "",
    });
  };

  const handleModalSubmit = (newText, mode = 'replace') => {
    const editorInstance = editorRef.current.getInstance();
    const textToInsert = newText || modalConfig.streamedContent;

    if (textToInsert) {
      const { start, end } = cursorOffsetRef.current;

      // 마크다운 에디터로 포커스
      editorInstance.focus();

      if (mode === 'append') {
        // 선택 영역의 끝으로 커서 이동
        editorInstance.setSelection(end, end);        
        // 줄바꿈을 추가하고 새로운 텍스트 삽입
        const insertText = end > 0 ? `\n\n${textToInsert}` : textToInsert;
        editorInstance.replaceSelection(insertText);
      } else {
        // replace 모드: 기존 동작 유지
        editorInstance.setSelection(start, end);
        editorInstance.replaceSelection(textToInsert);
      }
    }

    handleModalClose();
  };

  const handleActionClick = async (action, text) => {
    const editorInstance = editorRef.current.getInstance();

    // 현재 선택 영역의 시작과 끝 위치를 저장
    const selection = editorInstance.getSelection();
    cursorOffsetRef.current = {
      start: selection[0],
      end: selection[1],
    };

    setModalConfig({
      isOpen: true,
      title: menuItems.find((item) => item.action === action).text,
      content: text,
      action: action,
      streamedContent: "",
    });

    try {
      await contentApi.enhance({ action, content: text }, (streamedContent) => {
        setModalConfig((prev) => ({
          ...prev,
          streamedContent: prev.streamedContent + streamedContent,
        }));
      });
    } catch (error) {
      console.error("Content enhancement failed:", error);
    }
  };

  const menuItems = [
    { icon: "⚡", text: "글 개선하기", action: "enhance" },
    { icon: "📋", text: "요약하기", action: "summarize" },
    { icon: "🔄", text: "단순화하기", action: "simplify" },
    { icon: "👔", text: "공식적인 톤으로 변경", action: "formal" },
    { icon: "🗣️", text: "친근한 톤으로 변경", action: "casual" },
    { icon: "📝", text: "내용 확장하기", action: "expand" },
    { icon: "🎨", text: "창의적으로 다시쓰기", action: "creative" },
    { icon: "🎓", text: "학술적 스타일로 변경", action: "academic" },
    { icon: "⚙️", text: "전문적인 용어로 변경", action: "technical" },
    { icon: "✍️", text: "문법 검사 및 교정", action: "proofread" },
    { icon: "•", text: "글머리 기호로 변환", action: "bullets" },
    { icon: "📑", text: "단락 구조로 변경", action: "paragraphs" },
    { icon: "📊", text: "표 형식으로 변환", action: "table" },
    { icon: "📈", text: "다이어그램 생성", action: "mermaid" }
];
  const closePopoverWidget = () => {
    if (popoverRef.current && popoverRef.current.parentElement) {
        // DOM에서 직접 요소를 제거
        popoverRef.current.parentElement.removeChild(popoverRef.current);
        popoverRef.current = null;
      }
  };
  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;

    const editorInstance = editor.getInstance();
    const mdEditor = editorInstance.getEditorElements().mdEditor;

    const createPopoverWidget = (selectedText, position, editorRect) => {
      if (popoverRef.current) {
        // editorInstance.removeWidget(popoverRef.current);
        // closePopoverWidget();
        popoverRef.current = null;
      }

      selectedTextRef.current = selectedText;

      const popoverEl = document.createElement("div");
      popoverEl.className = "text-selection-popover";

      // 위젯의 크기를 미리 설정 (예상 크기)
      const WIDGET_HEIGHT = 300; // 메뉴 아이템 수에 따라 조정
      const WIDGET_MARGIN = 10; // 여백

      // 위젯이 아래쪽에 위치할 경우 필요한 공간
      const spaceBelow = editorRect.height - (position.top - editorRect.top);
      // 위젯이 위쪽에 위치할 경우 필요한 공간
      const spaceAbove = position.top - editorRect.top;

      // 위젯을 위에 표시할지 아래에 표시할지 결정
      const showAbove =
        spaceBelow < WIDGET_HEIGHT + WIDGET_MARGIN && spaceAbove > spaceBelow;

      popoverEl.style.cssText = `
    position: absolute;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 4px 0;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 100;
    font-size: 14px;
    min-width: 180px;
  `;

      const menuHtml = menuItems
        .map(
          (item) => `
  <div class="menu-item" data-action="${item.action}" style="
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    user-select: none;
    height: 24px;
  ">
    <span style="margin-right: 8px; font-size: 14px;">${item.icon}</span>
    <span style="color: #333; font-size: 13px;">${item.text}</span>
  </div>
`
        )
        .join("");

      popoverEl.innerHTML = menuHtml;

      const items = popoverEl.querySelectorAll(".menu-item");
      items.forEach((item) => {
        item.addEventListener("mouseenter", () => {
          item.style.backgroundColor = "#f5f5f5";
        });

        item.addEventListener("mouseleave", () => {
          item.style.backgroundColor = "transparent";
        });

        item.addEventListener("mousedown", (e) => {
          e.stopPropagation();
          e.preventDefault();

          const action = e.currentTarget.dataset.action;
          const text = selectedTextRef.current;

          // 팝오버 위젯 닫기
          // closePopoverWidget();

          // handleActionClick 호출
          handleActionClick(action, text);
        });
      });

      const widget = editorInstance.addWidget(popoverEl, {
        position: position,
        offset: {
          x: 0,
          y: showAbove ? -WIDGET_HEIGHT - WIDGET_MARGIN : WIDGET_MARGIN,
        },
      });

      popoverRef.current = popoverEl;

      const handleClickOutside = (e) => {
        const isMenuItem = e.target.closest(".menu-item");
        if (!isMenuItem && popoverRef.current) {
        // closePopoverWidget();
          document.removeEventListener("click", handleClickOutside);
        }
      };

      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 0);
    };

    const handleMouseUp = (e) => {
      if (e.target.closest(".menu-item")) {
        return;
      }

      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const editorRect = mdEditor.getBoundingClientRect();

        const position = {
          top: rect.bottom - editorRect.top + window.scrollY,
          left: rect.left - editorRect.left + window.scrollX,
        };

        createPopoverWidget(selectedText, position, editorRect);
      }
    };

    const handleMouseMove = (e) => {
      const selection = window.getSelection();
      if (selection.toString()) {
        if (popoverRef.current) {
        //   editorInstance.removeWidget(popoverRef.current);
          popoverRef.current = null;
        }
      }
    };

    mdEditor.addEventListener("mouseup", handleMouseUp);
    mdEditor.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (mdEditor) {
        mdEditor.removeEventListener("mouseup", handleMouseUp);
        mdEditor.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [editorRef]);

  return (
    <>
      <Editor
        initialValue={content || ""}
        previewStyle="tab"
        height={height}
        initialEditType="markdown"
        useCommandShortcut={true}
        ref={editorRef}
        hideModeSwitch={true}
        onChange={onChange}
        plugins={[uml]}
        hooks={{addImageBlobHook:addImageBlobHook}}
      />

      {modalConfig.isOpen && (
        <Modal
          action={modalConfig.action}
          title={modalConfig.title}
          content={modalConfig.content}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          streamedContent={modalConfig.streamedContent}
        />
      )}
    </>
  );
};

export default MDEditor;
