import React from "react";
import { ModalBackdrop } from "../ModalBackdrop";
import Markdown from "marked-react";

const Modal = ({ action, title, content, streamedContent, onClose, onSubmit }) => {
  const handleSubmit = (e, mode = 'replace') => {
    e.preventDefault();
    // form 엘리먼트를 직접 찾아서 사용
    const form = e.target.closest('form');
    const textarea = form.querySelector('textarea[name="content"]');
    const text = textarea.value;
      onSubmit(`\n${text}\n`, mode);
    
    // if (mode === 'append') {
    //   const combinedText = `${content}\n\n${text}`;
    //   onSubmit(combinedText, mode);
    // } else {
    //   onSubmit(text, mode);
    // }
  };

  return (
    <ModalBackdrop onClose={onClose}>
    <div
      className="bg-white rounded-lg p-6 w-full max-w-4xl"
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <form onSubmit={(e) => handleSubmit(e, 'replace')}>      {/* Original Content */}
          <div className="mb-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">원본 내용:</h3>
            <div className="p-4 bg-gray-50 rounded-lg">
              <Markdown>{content || ""}</Markdown>
            </div>
          </div>
          
          {/* Streamed Content */}
          <div className="mb-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">변환된 내용:</h3>
            <textarea
              name="content"
              className="w-full h-96 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              defaultValue={streamedContent || ""}
              readOnly={!!streamedContent}
            />
          </div>
          
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded-lg hover:bg-gray-100"
            >
              취소
            </button>
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e, 'append');
              }}
              className="px-4 py-2 border bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              추가
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              변경
            </button>
          </div>
        </form>
      </div>
    </ModalBackdrop>
  );
};

export default Modal;